import React, { useEffect, useState } from "react";
import Profile from "../Components/Profile";
import FormModal from "../Components/FormModal";
import ImageModal from "../Components/ImageModal";
import visaLogo from "../Assets/visa-logo.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
export default function UserProfile() {
  const [loading, setLoading] = useState(true);
  const [degree, setDegree] = useState("");
  const [education, setEducation] = useState([]);
  const [profession, setProfession] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showImageModal, setImageModal] = useState(false);

  const [document, setDocument] = useState([]);
  const state = useSelector((state) => {
    return state.user;
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEducationData = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}api/get/education/${state?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          }
        )
        .then((response) => {
          setEducation(response?.data?.data);
        })
        .catch((err) => {
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
        });
    };

    fetchEducationData();
  }, [state?.user?.id, state?.token, education]);

  useEffect(() => {
    const fetchEducationData = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}api/get/profession/${state?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          }
        )
        .then((response) => {
          setProfession(response?.data?.data);
        })
        .catch((err) => {
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
        });
    };

    fetchEducationData();
  }, [state?.user?.id, state?.token, profession]);
  useEffect(() => {
    const fetchEducationData = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}api/get/document/${state?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          }
        )
        .then((response) => {
          setDocument(response?.data?.data);
        })
        .catch((err) => {
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
        });
    };

    fetchEducationData();
  }, [state?.user?.id, state?.token, document, degree]);

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL +
            `api/get/user/profile/${state?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          }
        );

        setQuestions(res?.data?.questions);
      } catch (err) {}
    };
    loadQuestions();
  }, []);

  useEffect(() => {
    if (state?.login == false) {
      navigate("/");
    }
  }, [state?.login]);

  return (
    <>
      {/* User Detail modal */}
      <FormModal
        className={"modal-md"}
        show={showPaymentModal}
        handleClose={() => setShowPaymentModal(false)}
      >
        <GetFeaturedPlan setClose={setShowPaymentModal} />
      </FormModal>
      <ImageModal
        showImageModal={showImageModal}
        setImageModal={setImageModal}
        degree={degree}
      />
      <Profile
        setEducation={setEducation}
        userData={state?.user}
        setDegree={setDegree}
        setImageModal={setImageModal}
        user={true}
        education={education}
        profession={profession}
        document={document}
        setDocument={setDocument}
        setProfession={setProfession}
        state={state}
        setShowPaymentModal={setShowPaymentModal}
        questions={questions}
        setQuestions={setQuestions}
      />
    </>
  );
}
function GetFeaturedPlan({ setClose }) {
  const [current, setCurrent] = useState(0);
  const state = useSelector((state) => {
    return state.user;
  });

  const [data, setData] = useState({
    email: state.user.email,
    phone: state.user.phone,
  });

  if (current == 0) {
    return (
      <div className="px-4 px-sm-5 pt-4 pb-5">
        <h6 className="text-center font-700 mb-3 ">User Detail</h6>
        <p className="text-center font-500">
          Feature your profile for premium perks and exclusive benefits! 🎉
        </p>
        <div className=" form-group mb-4">
          <label className="label form-label">Phone</label>
          <input
            value={data?.phone}
            type="text"
            placeholder="Enter phone number"
            className="form-control"
            readOnly
          />
        </div>
        <div className=" form-group mb-4">
          <label className="label form-label">Email</label>
          <input
            value={data?.email}
            type="email"
            placeholder="Enter your email"
            className="form-control"
            readOnly
          />
        </div>
        <div className="text-center pt-5 mt-5">
          <button
            className="btn clr-btn-theme px-5"
            onClick={() => setCurrent(1)}
          >
            Purchase a Plan
          </button>
        </div>
      </div>
    );
  } else if (current == 1) {
    return <PaymentModal setClose={setClose} />;
  }
}
function PaymentModal({ setClose }) {
  const [loader, setLoader] = useState(false);
  const state = useSelector((state) => {
    return state.user;
  });
  const purchasePlan = async () => {
    setLoader(true);
    const formData = {
      other_id: state?.user?.id,
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}api/user/feature/profile/${state?.user?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.message == "Profile Already Featured") {
          toast.error("Profile Already Featured");
          setClose(false);
          setLoader(false);
        }
        if (res?.data?.message == "Profile Featured Successfully") {
          toast.success("Profile Featured Successfully");
          setClose(false);
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error("There is something went wrong!");
        setLoader(false);
        setLoader(false);
      });
  };
  return (
    <div className="px-4 px-sm-5 pt-4 pb-5">
      <h5 className="text-center font-700 mb-3">Payment</h5>
      <p className="font-500 text-small mb-2">Payment amount</p>
      <h3 className="font-700 mb-3">$50.00</h3>
      <img src={visaLogo} className="payment-icon mb-3" alt="" />
      <div className=" form-group mb-4">
        <label className="label form-label">Name</label>
        <input
          type="text"
          placeholder="Name on card"
          className="form-control"
        />
      </div>
      <div className="form-group mb-4">
        <label className="label form-label">Card number</label>
        <input
          type="email"
          placeholder="0000 0000 0000 0000"
          className="form-control"
        />
      </div>
      <div className="row">
        <div className="form-group mb-4 col-6">
          <label className="label form-label">Expiry</label>
          <input type="text" placeholder="MM/YY" className="form-control" />
        </div>
        <div className="form-group mb-4 col-6">
          <label className="label form-label">CVV</label>
          <input type="text" placeholder="CVV" className="form-control" />
        </div>
      </div>
      <div className="text-center mt-5">
        <button
          disabled={loader}
          className="btn clr-btn-theme px-5"
          onClick={purchasePlan}
        >
          {loader ? <Loading stroke="4" width="20" height="20" /> : "Pay $100"}
        </button>
      </div>
    </div>
  );
}
