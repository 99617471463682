import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./Firebase";
import { useSelector } from "react-redux";

const Passions = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const handleClick = (id) => {
    navigate(`/chat/${id}`);
  };

  useEffect(() => {
    const fetchUsersWithChats = async () => {
      try {
        const usersCollection = collection(db, "users");
        const querySnapshot = await getDocs(usersCollection);

        const fetchChatsForUser = async (userId) => {
          const chatId = state.user.id + userId;
          const reverseChatId = userId + state.user.id;

          const chatRef1 = collection(db, `chats/${chatId}/messages`);
          const chatRef2 = collection(db, `chats/${reverseChatId}/messages`);

          const [chatSnapshot1, chatSnapshot2] = await Promise.all([
            getDocs(chatRef1),
            getDocs(chatRef2),
          ]);

          let lastMessage = null;

          // Check if there are any messages in the first chat reference
          if (!chatSnapshot1.empty) {
            lastMessage =
              chatSnapshot1.docs[chatSnapshot1.docs.length - 1].data();
          }

          // Check if there are any messages in the second chat reference
          if (!chatSnapshot2.empty) {
            const latestInReverseChat =
              chatSnapshot2.docs[chatSnapshot2.docs.length - 1].data();
            if (
              !lastMessage ||
              new Date(latestInReverseChat.createdAt) >
                new Date(lastMessage.createdAt)
            ) {
              lastMessage = latestInReverseChat;
            }
          }

          return lastMessage
            ? {
                id: userId,
                lastMessage,
                ...querySnapshot.docs.find((doc) => doc.id === userId).data(),
              }
            : null;
        };

        // Fetch all chat data concurrently for each user
        const chatPromises = querySnapshot.docs
          .filter((docSnap) => docSnap?.id !== state.user?.id) // Skip current user
          .map((docSnap) => fetchChatsForUser(docSnap?.id));

        // Resolve all promises and filter out any null results
        const usersWithChats = await Promise.all(chatPromises);
        const validUsersWithChats = usersWithChats.filter(
          (result) => result !== null
        );

        // Sort users by the timestamp of the latest message
        const sortedUsers = validUsersWithChats.sort((a, b) => {
          return (
            new Date(b.lastMessage.createdAt) -
            new Date(a.lastMessage.createdAt)
          );
        });

        setData(sortedUsers);
      } catch (error) {
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchUsersWithChats();
  }, [state.login, navigate, data]);

  return (
    <div className="p-3 background-container same-passions">
      <h5 className="mb-sm-4 mb-3 main-heading">Recent Chats</h5>
      {state?.login ? (
        data.length > 0 ? (
          <>
            {data.slice(0, 3).map((user, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between mb-sm-4 mb-3"
              >
                <div className="d-flex align-items-center">
                  <div className="overflow-hidden image-holder">
                    {user.gender == "Male" || user.gender == "male" ? (
                      <img
                        src={
                          user?.image
                            ? process.env.REACT_APP_API_URL + user.image
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                        className="user-pic"
                        alt="User Pic"
                      />
                    ) : (
                      <img
                        src={
                          user?.image
                            ? process.env.REACT_APP_API_URL + user.image
                            : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                        }
                        className="user-pic"
                        alt="User Pic"
                      />
                    )}
                  </div>
                  <div className="ms-2">
                    <Link
                      to={`/visit-user-profile/${user?.id}`}
                      className="text-decoration-none text-dark"
                    >
                      <h6 className="mb-0 user-name">
                        {user?.name?.length > 8
                          ? user.name.slice(0, 8) + "..."
                          : user.name}
                      </h6>
                    </Link>
                    <span className="profession">
                      {user.profession
                        ? user.profession.length > 15
                          ? `${user.profession.slice(0, 15)}...`
                          : user.profession
                        : "N/A"}{" "}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => {
                    handleClick(user?.id);
                  }}
                  className="py-1 btn transparent-btn-theme"
                >
                  Message
                </button>
              </div>
            ))}
            <div className="text-center">
              <NavLink to="/chat" className="theme-link">
                See all
              </NavLink>
            </div>
          </>
        ) : (
          <p className="text-center text-light text-small py-3">No chats</p>
        )
      ) : (
        <div className="text-light text-small text-center py-4">
          Sign In to do chats
        </div>
      )}
    </div>
  );
};

export default Passions;
