import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import { GlobalContext } from "./GlobalProvider";
import MyInfo from "../Components/MyInfo";
import Passions from "../Components/Passions";
import UserProfiles from "../Components/UserProfiles";
import Loading from "../Components/Loading";
import Filters from "../Components/Filters";
import { toast } from "react-toastify";
import { logOutUser } from "../store/authSlices";
import Download from "../Components/Download";

const FeaturedProfiles = () => {
  const { filters, setFilters } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state) => state?.user);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  // Read filters from URL on page load
  const parsedFilters = queryString.parse(location.search); // Parse the query string from the URL

  useEffect(() => {
    setLoading(true);
    setFilters(parsedFilters); // Set the parsed filters in the global context
    // Fetch data with the new filters
    fetchData(parsedFilters);
  }, [location.search, setFilters]); // Trigger on URL change or filters change

  const fetchData = async (filterParams) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/user/get/featured/profiles/${state?.user?.id}`,
        filterParams, // Send parsed filters in the API request
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      );
      setData(response?.data?.data);
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.message == "Token has expired") {
        toast.error("Your token has been expired again sign in");
        dispatch(logOutUser());
        return;
      }
      setLoading(false);
      if (err?.message === "Network Error") {
        toast.error("Check your internet Connection");
      }
    }
  };

  useEffect(() => {
    if (state.login == false) {
      toast.warning("Sign in to view all matching featured profiles");
    }
  }, [state.login]);

  return (
    <div className="container-fluid px-xl-5 px-sm-4">
      <div className="row mx-0">
        <div className="col-xl-3 px-xl-2 p-0">
          <MyInfo />
          <Filters />
        </div>
        <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
          <div className="p-3 background-container">
            {state.login == false && loading == false && (
              <p className="small text-light m-0 p-0 text-center">
                Sign in to view all matching featured profiles
              </p>
            )}
            {loading ? (
              <Loading stroke="4" />
            ) : data.length === 0 && Object.keys(parsedFilters).length > 0 ? (
              <p className="text-center text-light p-0 m-0">
                No matching profile exists
              </p>
            ) : (
              <UserProfiles data={data} />
            )}
          </div>
        </div>
        <div className="col-xl-3 px-xl-2 p-0">
          <Passions />
          <Download />
        </div>
      </div>
    </div>
  );
};

export default FeaturedProfiles;
