import { useRef, useState } from "react";
import PasswordInput from "../Components/PasswordInput";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
const ChangePassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let email = localStorage.getItem("email");

  const [data, setData] = useState({
    userPass: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    userPass: "",
    confirmPassword: "",
  });

  const inputRef = {
    userPass: useRef(),
    confirmPassword: useRef(),
  };

  function onChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (value) {
      setError((prev) => {
        return {
          ...prev,
          [name]: "",
        };
      });
    } else {
      setError((prev) => {
        return {
          ...prev,
          [name]: "required",
        };
      });
    }
  }

  function updatePassword() {
    let a = null;
    let formData = {
      email,
      password: data.userPass,
      confirm_password: data.confirmPassword,
    };

    Object.keys(data).forEach((e) => {
      if (data[e] === "" || !data[e]) {
        setError((prev) => {
          return {
            ...prev,
            [e]: `${e} is required`,
          };
        });
        setLoading(false);
      }
      if (a === null) {
        a = e;
      }
    });

    if (a) {
      inputRef[a]?.current.focus();
      inputRef[a]?.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }

    const areAllErrorsEmpty = (obj) => {
      return Object.values(obj).every((value) => {
        if (typeof value === "object" && value !== null) {
          return areAllErrorsEmpty(value);
        }

        return value === "";
      });
    };

    if (!email) {
      navigate("/forgot-password");
      toast.error("There is something went wrong!");
      setLoading(false);
    }

    if (areAllErrorsEmpty(error)) {
      if (
        formData.password === formData.confirm_password &&
        formData.password != "" &&
        formData.confirm_password != ""
      ) {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}api/user/password/reset`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })
          .then((Response) =>
            Response.json().then((result) => {
              if (result.status == "success") {
                setLoading(false);
                toast.success(result.message);
                localStorage.removeItem("email");
                navigate("/sign-in");
              }
              if (result.status == "failed") {
                setLoading(false);
                toast.error(result.message);
              }
            })
          )
          .catch(() => {
            setLoading(false);
            toast.error("There is something went wrong!");
          });
      } else {
        setLoading(false);
        toast.error("Password should be same!");
      }
    }
  }
  return (
    <div className="py-5 px-4 container-fluid sigin-form forms">
      <div className="row">
        <div className="col-xl-7 col-lg-8 col-md-9 col-sm-1 mx-auto">
          <div
            className="shadow bg-white rounded-2 pb-5"
            style={{ overflow: "hidden" }}
          >
            <div className="form-header position-relative py-4 ps-3 ps-sm-4 ps-lg-5">
              <h4 className="text-white py-3 mb-0">Change Password</h4>
              <div className="title">
                <div className="PolygonRuler">
                  <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="PolygonRuler__SVG"
                  >
                    <polygon
                      points="2560 0 2560 100 0 100"
                      className="PolygonRuler__Polygon PolygonRuler__Polygon--fill-white"
                    ></polygon>
                  </svg>
                </div>
              </div>
            </div>
            <div className="px-3 px-sm-4 px-lg-5 pb-4 mt-4">
              <p className="small-heading mb-4">Enter your new password</p>
              <div className="row">
                <div className="mb-4">
                  <div className="form-group">
                    <PasswordInput
                      label={"Password"}
                      placeholder={"Enter your password"}
                      name="userPass"
                      value={data.userPass}
                      onChange={onChange}
                      reference={inputRef.userPass}
                    />
                    {error.userPass && (
                      <p className="text-danger mt-2 small">
                        Password is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <PasswordInput
                      label={"Confirm Password"}
                      placeholder={"Enter confirm password"}
                      name="confirmPassword"
                      value={data.confirmPassword}
                      onChange={onChange}
                    />
                    {error.confirmPassword && (
                      <p className="text-danger mt-2 small">
                        Confirm Password is required
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <button
                  disabled={loading}
                  className="btn auth-btn clr-btn-theme register-btn py-2"
                  onClick={updatePassword}
                >
                  {loading ? (
                    <Loading stroke="4" width="20" height="20" />
                  ) : (
                    "Confirm Password"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
