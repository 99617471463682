import MyInfo from "../Components/MyInfo";
import Passions from "../Components/Passions";
import FormModal from "../Components/FormModal";
import { useContext, useEffect, useRef, useState } from "react";
import Ads from "../Components/Ads";
import FeaturedProfiles from "../Components/FeaturedProfiles";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
import Filters from "../Components/Filters";
import { GlobalContext } from "./GlobalProvider";
import queryString from "query-string";
import { Link, useLocation } from "react-router-dom";
import Download from "../Components/Download";

const Feeds = () => {
  const state = useSelector((state) => {
    return state.user;
  });
  const { filter } = useContext(GlobalContext);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const ref = useRef();
  const [hasMore, setHasMore] = useState(true);
  const [loader, setLoader] = useState(false);
  const userId = state?.user?.id;
  const token = state?.token;

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [feed, setFeed] = useState([]);
  const [text, setText] = useState("");
  const [error, setError] = useState(false);
  const wordLimit = 300;
  const [words, setWords] = useState(0);
  const handleCopy = (e) => e.preventDefault();
  const handleCut = (e) => e.preventDefault();
  const handlePaste = (e) => e.preventDefault();

  // Function to count words in a string
  const countWords = (str) => {
    return str
      .trim()
      .split(/\s+/)
      .filter((word) => word !== "").length;
  };

  const handleChange = (e) => {
    const inputText = e.target.value;

    if (inputText !== "") {
      setError(false);
    }

    const wordsCount = countWords(inputText);

    // Only update if word count is within the limit
    if (wordsCount <= wordLimit) {
      setText(inputText);
      setWords(wordsCount); // Update words count in state
    }
  };

  const changeSkip = () => {
    clearTimeout(ref.current); // Clear the existing timeout

    if (loader || !hasMore) return; // Prevent unnecessary requests

    let height = window.innerHeight;
    let scrollHeight = document.documentElement.scrollHeight;
    let scrollPosition = document.documentElement.scrollTop;

    // Check if the user has reached near the bottom of the page
    if (height + scrollPosition + 1 >= scrollHeight) {
      ref.current = setTimeout(() => {
        setPage((prev) => prev + 1); // Load the next page
      }, 500); // Debounce to 500ms
    }
  };

  const fetchData = async (page, filters) => {
    if (loading == false) {
      setLoader(true);
    }
    const formData = {
      page,
      filters,
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}api/user/get/feeds/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.length <= 3) {
          setHasMore(false); // No more data to load
        }
        setFeed((prev) => [...prev, ...response.data.data]);
        setLoading(false);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.message === "Network Error" && state?.login == true) {
          toast.error("Check your internet Connection");
        }
        setLoading(false);
        setLoader(false);
      });
  };

  useEffect(() => {
    window.addEventListener("scroll", changeSkip);

    return () => {
      window.removeEventListener("scroll", changeSkip); // Cleanup
    };
  }, [loader, hasMore]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const appliedFilters = {
      ...queryParams,
      ...filter,
    };

    if (state.login === true) {
      fetchData(page, appliedFilters);
    }
  }, [location.search, filter, page]);

  const handlePost = async () => {
    if (state?.login == false) {
      toast.error("Login to post");
      return;
    }
    if (text == "") {
      setError(true);
      return;
    }

    const formData = {
      description: text,
    };
    setBtnLoading(true);
    await axios
      .post(
        process.env.REACT_APP_API_URL + `api/user/post/feed/${state?.user?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      )
      .then((res) => {
        if (res?.data?.message == "Your Profile is not Featured") {
          toast.error("Featured your profile to make a post");
          setBtnLoading(false);
          setShow(false);
          setText("");
          return;
        }
        toast.success(res?.data?.message);
        setBtnLoading(false);
        setShow(false);
        setText("");
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setBtnLoading(false);
        setShow(true);
        setText("");
      });
  };

  const fetchData2 = async (page) => {
    if (!loading && hasMore) {
      // Ensure you only fetch when not loading and there is more data
      setLoader(true);

      if (state.login === false) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/feeds?page=${page}`
          );

          // Check if the response has fewer than 10 items (page size)
          if (response.data.data.length < 10) {
            setHasMore(false); // No more data to load
          }

          // Append new data to the existing feed
          setFeed((prevFeed) => [...prevFeed, ...response.data.data]);
          setLoading(false);
        } catch (err) {
          setLoading(false);

          if (err?.message === "Network Error" && state?.login === true) {
            toast.error("Check your internet connection");
            setLoading(false);
          }
          setLoading(false);
        } finally {
          setLoader(false);
        }
      }
    } else {
      setLoader(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData2(page); // Call API when `page` or `state` changes
  }, [state, page]);

  return (
    <>
      <FormModal
        className={"modal-lg"}
        show={show}
        handleClose={() => setShow(false)}
      >
        <div className="px-lg-3">
          <div className="p-4 p-lg-5 send-message-modal">
            <div className="d-flex gap-3 mb-3 align-items-center">
              <div className="image-holder">
                {state?.user?.gender == "Male" ||
                state?.user?.gender == "male" ? (
                  <img
                    className="user-img-in-message-modal"
                    src={
                      state?.user?.image
                        ? `${process.env.REACT_APP_API_URL + state.user.image}`
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                ) : (
                  <img
                    className="user-img-in-message-modal"
                    src={
                      state?.user?.image
                        ? `${process.env.REACT_APP_API_URL + state.user.image}`
                        : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                    }
                    alt=""
                  />
                )}
              </div>
              <div>
                <h6 className="mb-1 font-600">
                  {state?.user?.name ? state?.user?.name : "User Name"}
                </h6>
                <p className="text-small text-light mb-0">
                  {state?.user?.profession}
                </p>
              </div>
            </div>
            <div className="ps-sm-3 form-group text-container">
              <textarea
                value={text}
                name="post"
                onChange={handleChange}
                // onCopy={handleCopy}
                // onCut={handleCut}
                // onPaste={handlePaste}
                rows={7}
                placeholder="Type here"
                className="form-control p-3"
              ></textarea>
              {error && (
                <p className="text-danger mt-2 text-small">
                  Post should not be empty
                </p>
              )}
              <p
                className={`${
                  words == 300 ? "text-danger" : "text-light"
                } mt-2`}
              >
                Words: {words}/300
              </p>
            </div>
            <div className="text-center">
              <button
                disabled={btnLoading}
                onClick={handlePost}
                className="btn auth-btn clr-btn-theme feed-btn"
              >
                {btnLoading ? (
                  <Loading stroke="4" width="20" height="20" />
                ) : (
                  "Post"
                )}
              </button>
            </div>
          </div>
        </div>
      </FormModal>
      <div className="container-fluid px-xl-5 px-sm-4">
        <div className="row mx-0">
          <div className="col-xl-3 px-xl-2 p-0">
            <MyInfo />
            <Filters />
          </div>
          <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
            <div className="mb-3 p-sm-4 p-2 background-container">
              <div className="d-flex align-items-center feed-post-input">
                <div className="me-3">
                  <div className="img-holder">
                    {state.user?.gender == "Male" ||
                    state.user?.gender == "male" ? (
                      <img
                        src={
                          state?.user?.image
                            ? `${
                                process.env.REACT_APP_API_URL + state.user.image
                              }`
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                      />
                    ) : (
                      <img
                        src={
                          state?.user?.image
                            ? `${
                                process.env.REACT_APP_API_URL + state.user.image
                              }`
                            : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                        }
                      />
                    )}
                  </div>
                </div>
                <input
                  className="w-100"
                  onClick={() => setShow(true)}
                  placeholder={`Is there anything you'd like to share ${
                    state?.user?.name ? `, ${state?.user?.name}` : ""
                  }?`}
                  readOnly
                />
              </div>
            </div>
            <div className="p-sm-4 p-3 background-container">
              {loading ? (
                <Loading stroke={4} />
              ) : (
                <>
                  {feed.map((e, index) => {
                    if (e?.user_id) {
                      // Render UserFeed if user data exists
                      return (
                        <div className="mb-4" key={`user-${index}`}>
                          <UserFeed e={e} />
                        </div>
                      );
                    } else if (e?.name) {
                      // Render FeaturedProfiles if the object has a 'name' field
                      return (
                        <div className="mb-4" key={`profile-${index}`}>
                          <FeaturedProfiles data={[e]} />
                        </div>
                      );
                    } else if (e?.title) {
                      // Render Ads if the object has a 'title' field
                      return (
                        <div key={`ad-${index}`}>
                          <Ads data={[e]} />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
              {loader == true && <Loading height={30} width={30} />}
              {hasMore == false && (
                <p className="text-small text-center mt-4">
                  No more data to show
                </p>
              )}
            </div>
          </div>
          <div className="col-xl-3 px-xl-2 p-0">
            <Passions />
            <Download />
          </div>
        </div>
      </div>
    </>
  );
};

export default Feeds;

const UserFeed = ({ e }) => {
  const userFeed = e;

  return (
    <>
      <div className="mb-4 d-flex border-bottom user-feed">
        <div className="me-2">
          <div className="img-holder">
            {userFeed?.user?.gender == "Male" ||
            userFeed?.user?.gender == "male" ? (
              <img
                src={
                  userFeed?.user?.image
                    ? `${process.env.REACT_APP_API_URL + userFeed.user.image}`
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
              />
            ) : (
              <img
                src={
                  userFeed?.user?.image
                    ? `${process.env.REACT_APP_API_URL + userFeed.user.image}`
                    : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                }
              />
            )}
          </div>
        </div>
        <div>
          <div className="mt-2 mb-3">
            <Link
              className="text-decoration-none text-dark"
              to={`/visit-user-profile/${userFeed?.user?.id}`}
            >
              <h4 className="mb-0 username">{userFeed?.user?.name}</h4>
            </Link>
            <p className="mb-0 profession">{userFeed?.user?.profession}</p>
          </div>
          <p>{userFeed?.description}</p>
        </div>
      </div>
    </>
  );
};
