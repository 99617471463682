import { Link } from "react-router-dom";

const Ads2 = (props) => {
  const ads = props.data;
  return (
    <>
      {ads?.map((ads, index) => {
        // Original timestamp
        const timestamp = ads.created_at;

        // Create a variable to hold the formatted date
        let formattedDate = "Invalid date";

        if (timestamp) {
          // Create a new Date object
          const date = new Date(timestamp);

          // Define options for formatting the date
          const options = { weekday: "short", month: "short" };

          // Format the date using toLocaleDateString
          const formattedDatePart = date.toLocaleDateString("en-US", options);
          const year = date.getFullYear();

          // Construct the formatted date as 'Sat, Aug 2024'
          formattedDate = `${formattedDatePart}, ${year}`;
        }

        return (
          <div className="mb-sm-4 mb-3 overflow-hidden ads" key={index}>
            <img
              src={`${process.env.REACT_APP_API_URL + ads.image}`}
              className="ads-image"
            />
            <div className="row mx-0 my-3">
              <div className="col-sm-8">
                <h5 className="heading">{ads.title}</h5>
                <p className="mb-0 date">{formattedDate}</p>
              </div>
              <div className="col-sm-4 text-end">
                <Link to={`/ad-detail/${ads?.id}`}>
                  <button className="px-4 btn transparent-btn-theme">
                    View
                  </button>
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Ads2;
