import { useEffect, useState } from "react";
import camera_icon from "../Assets/camera-icon.png";

function UserLogo({ name, image, user = false, onChange, gender }) {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (typeof image === "string") {
      // If image is a string (URL from backend), use it directly
      setImageUrl(process.env.REACT_APP_API_URL + image);
    } else if (image instanceof Blob || image instanceof File) {
      // If image is a Blob or File, create an object URL
      const url = URL.createObjectURL(image);
      setImageUrl(url);

      // Cleanup URL when component unmounts or when a new image is loaded
      return () => URL.revokeObjectURL(url);
    } else {
      if (gender == "male" || gender == "Male") {
        setImageUrl(
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
        );
      } else {
        setImageUrl(
          "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
        );
      }
    }
  }, [image]);

  return (
    <div className="user-logo">
      <img className="user-img" src={imageUrl} alt="User Logo" />
      {user && (
        <>
          <label
            htmlFor="user"
            className="camera-icon d-flex justify-content-center align-items-center"
          >
            <img src={camera_icon} className="icon-in" alt="Upload Icon" />
          </label>
          <input
            id="user"
            type="file"
            accept="image/*"
            onChange={(e) =>
              onChange({
                target: {
                  value: e.target.files[0],
                  name,
                },
              })
            }
            className="d-none"
          />
        </>
      )}
    </div>
  );
}

export default UserLogo;
