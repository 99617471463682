import React, { useEffect, useState, useContext } from "react";
import MyInfo from "../Components/MyInfo";
import Passions from "../Components/Passions";
import UserProfiles from "../Components/UserProfiles.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading.js";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { GlobalContext } from "../pages/GlobalProvider"; // Import the global context
import Filters from "../Components/Filters.js";
import { logOutUser } from "../store/authSlices.js";
import Download from "../Components/Download.js";

const AllSamePassions = () => {
  const dispatch = useDispatch();
  const { filters } = useContext(GlobalContext); // Access global filters
  const state = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    const appliedFilters = {
      ...filters,
      ...queryParams,
    };

    fetchData(appliedFilters);

    // Scroll to top of the page when filters or URL change
    window.scrollTo(0, 0);
  }, [location.search, filters]); // Trigger on URL change or filters change

  // Fetch data based on applied filters
  const fetchData = async (filterParams) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/user/get/same/passions/${state?.user?.id}`,
        filterParams, // Pass filters as request body
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      );
      setData(response?.data?.data);
      setLoading(false);
    } catch (err) {
      if (err?.response?.data?.message == "Token has expired") {
        toast.error("Your token has been expired again sign in");
        dispatch(logOutUser());
        return;
      }
      setLoading(false);
      if (err?.message === "Network Error" && state?.login == true) {
        toast.error("Check your internet Connection");
      }
    }
  };

  useEffect(() => {
    if (state.login == false) {
      toast.warning("Sign in to view all same passion profiles");
    }
  }, [state.login]);

  return (
    <div className="container-fluid px-xl-5 px-sm-4">
      <div className="row mx-0">
        <div className="col-xl-3 px-xl-2 p-0">
          <MyInfo />
          <Filters />
        </div>
        <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
          <div className="p-3 background-container">
            {state.login == false && loading == false && (
              <p className="small text-light m-0 p-0 text-center">
                Sign in to view all same passion profiles
              </p>
            )}
            {loading ? (
              <Loading stroke="4" />
            ) : data.length === 0 && Object.keys(filters).length > 0 ? (
              <p className="text-center text-light p-0 m-0">
                No matching profile exists
              </p>
            ) : (
              <UserProfiles data={data} />
            )}
          </div>
        </div>
        <div className="col-xl-3 px-xl-2 p-0">
          <Passions />
          <Download />
        </div>
      </div>
    </div>
  );
};

export default AllSamePassions;
