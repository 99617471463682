import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBGal3CXgmpREoftXz5sVBNECvQBvxwx1k",
  authDomain: "sages-wedlocks.firebaseapp.com",
  projectId: "sages-wedlocks",
  storageBucket: "sages-wedlocks.appspot.com",
  messagingSenderId: "254828135351",
  appId: "1:254828135351:web:e9b7e15a9ae229caf85e32",
  measurementId: "G-WDKFQ3SRNJ",
  databaseURL: "https://sages-wedlocks.firebaseio.com", // Replace with your actual database URL
};

const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);
const auth = getAuth(firebase);
const authProvider = new GoogleAuthProvider();

export { firebase, db, auth, authProvider };
