import React, { useEffect, useRef, useState } from "react";
import SelectRegion from "../Components/SelectRegion";
import Select from "react-select";
import googleLogo from "../Assets/7123025_logo_google_g_icon.png";
import appleLogo from "../Assets/104490_apple_icon.png";
import PasswordInput from "../Components/PasswordInput";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { loginUser, updateUser } from "../store/authSlices";
import Loading from "../Components/Loading";
import { auth, authProvider, db } from "../Components/Firebase";
import { signInWithPopup } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import Form from "react-bootstrap/Form";

export default function Register() {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const credentials = {
    email: "",
    name: "",
    login_type: "google",
    social_id: "",
    fcm_token: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjZjYzN",
  };

  function handleSignUpGoogle() {
    signInWithPopup(auth, authProvider)
      .then((res) => {
        localStorage.setItem("email", res?.user?.email);
        localStorage.setItem("name", res?.user?.displayName);
        localStorage.setItem("phoneNumber", res?.user?.phoneNumber);
        localStorage.setItem(
          "social_id",
          res?.user?.reloadUserInfo?.providerUserInfo[0]?.federatedId
        );

        credentials.email = res?.user?.email;
        credentials.name = res?.user?.displayName;
        credentials.social_id =
          res?.user?.reloadUserInfo?.providerUserInfo[0]?.federatedId;

        submitCredentials();
      })
      .catch((err) => {});
  }

  async function submitCredentials() {
    setLoader(true);
    await axios
      .post(process.env.REACT_APP_API_URL + `api/socialLogin`, credentials)
      .then((res) => {
        localStorage.setItem("id", res?.data?.user?.id);
        localStorage.setItem("accessToken", res?.data?.access_token);

        if (res?.data?.type == "new") {
          navigate("/add-details");
          return;
        }

        if (res?.data?.message == "Your account is blocked by Admin!") {
          toast.error("Your account has been blocked");
          setLoader(false);
        }

        if (
          res?.data?.type !== "new" &&
          res?.data?.message == "User Login Successfully!"
        ) {
          toast.success("User Login Successfully!");
          dispatch(
            loginUser({
              userData: res?.data?.user,
              token: res?.data?.access_token,
            })
          );

          localStorage.clear();
          navigate("/");
          setLoader(false);
        } else {
        }
      })
      .catch((err) => {
        toast.error("There is something went wrong!");
        setLoader(false);
      });
  }

  let [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    gender: "",
    religion: "",
    ethnicity: "",
    cast: "",
    sects: [],
    userPass: "",
    confirmPassword: "",
    dob: {
      day: "",
      month: "",
      year: "",
    },
    sect: "",
    country: "",
    state: "",
    city: "",
    nativeCountry: "",
    nativeCity: "",
    nativeState: "",
  });

  function onChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "day" || name == "month" || name == "year") {
      setData((prev) => {
        return {
          ...prev,
          dob: { ...prev.dob, [name]: value },
        };
      });
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }

  return (
    <div className="py-5 px-4 container-fluid register-form forms">
      <div className="row">
        <div className="col-xl-9 col-lg-10 mx-auto">
          <div
            className="shadow bg-white rounded-2 pb-5"
            style={{ overflow: "hidden" }}
          >
            <div className="form-header position-relative py-4 ps-3 ps-sm-4 ps-lg-5">
              <h4 className="text-white py-3 mb-0">Register</h4>
              <div className="title">
                <div className="PolygonRuler">
                  <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="PolygonRuler__SVG"
                  >
                    <polygon
                      points="2560 0 2560 100 0 100"
                      className="PolygonRuler__Polygon PolygonRuler__Polygon--fill-white"
                    ></polygon>
                  </svg>
                </div>
              </div>
            </div>
            <form>
              <div className="px-3 px-sm-4 px-lg-5  mt-4">
                <p className="small-heading mb-4">Create your account</p>
                <EditUpdate
                  data={data}
                  onChange={onChange}
                  loader={loader}
                  checked={true}
                />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <p className="text-small text-center">
                    <span className="text-muted">
                      {" "}
                      Already have an account?{" "}
                    </span>
                    <span
                      onClick={() => navigate("/sign-in")}
                      className="font-700 pointer"
                    >
                      Sign in
                    </span>
                  </p>
                  <div className="d-flex justify-content-center align-items-center gap-3">
                    <span className="font-600 small">Sign In with</span>
                    <div
                      onClick={handleSignUpGoogle}
                      className="pointer google-login p-1 d-flex justify-content-center align-items-center"
                    >
                      {" "}
                      <img src={googleLogo} alt="" />
                    </div>
                    {/* <div className="pointer apple-login p-1 d-flex justify-content-center align-items-center">
                      <img src={appleLogo} alt="" />
                    </div> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function EditUpdate({
  data,
  onChange,
  formSubmit,
  flag = true,
  id = null,
  token = null,
  options,
  qualification,
  professions,
  loader,
  checked = false,
}) {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [check, setCheck] = useState(false);
  const [checkError, setCheckError] = useState(false);

  let cast = false;

  const addUserToFirestore = async (userId, profession, image) => {
    try {
      const userRef = doc(db, "users", JSON.stringify(userId));

      // Check if the user already exists in Firestore
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        // If the user exists, update the 'profession' and 'image' fields
        await updateDoc(userRef, {
          profession: profession,
          image: image,
        });
      }
    } catch (error) {}
  };

  if (
    data.nativeCountry === "PK,,Pakistan" ||
    data.nativeCountry === "BD,,Bangladesh" ||
    data.nativeCountry === "IN,,India"
  ) {
    cast = true;
  } else {
    cast = false;
  }

  async function formSubmit() {
    let dobDay = data.dob.day;
    let dobMonth = data.dob.month;
    let dobYear = data.dob.year;

    let dobString = `${dobDay}/${dobMonth}/${dobYear}`;

    let religionObj = userData?.religions?.find((elem) => {
      return elem.id == data.religion;
    });

    let countryName = data.country.split(",,");
    let nativeCountryName = data.nativeCountry.split(",,");
    let state = data?.state?.split(",,");
    let nativeState = data?.nativeState?.split(",,");

    let image = null;

    if (data?.image && typeof data?.image === "object") {
      image = data?.image;
    }
    let formData = new FormData();

    const formDataObject = {
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      gender: data?.gender?.toLowerCase(),
      religion: religionObj?.religion,
      religion_id: religionObj?.id,
      dob: dobString,
      ethnicity: data?.ethnicity,
      cast: data?.cast,
      sect: data?.sect,
      country_of_residence: countryName[1],
      city_of_residence: data?.city,
      state_of_residence: state?.[1],
      native_country: nativeCountryName[1],
      native_state: nativeState?.[1],
      native_city: data?.nativeCity,
      password: data?.userPass,
      confirmPassword: data?.confirmPassword,
      user_id: data?.id ? data.id : undefined,
      no_of_childs: data?.children,
      marital_status: data?.maritalStatus,
      home_size: data?.homeSize,
      home: data?.home,
      highest_qualification: data?.highestQualification,
      height: data?.height,
      financial_status: data?.financialStatus,
      hobbies: data?.hobbies,
      profession: data?.profession,
      country_of_residence_code: countryName[0],
      state_of_residence_code: state?.[0],
      native_state_code: nativeState?.[0],
      native_country_code: nativeCountryName[0],
    };

    for (const key in formDataObject) {
      if (formDataObject[key] !== undefined) {
        if (key === "hobbies") {
          const activities = formDataObject[key];
          formData.append(key, JSON.stringify(activities));
        } else {
          formData.append(key, formDataObject[key]);
        }
      }
    }

    if (image) {
      formData.append("image", image);
    }

    if (checked) {
      if (!check) {
        setCheckError(true);
        return;
      }
    }

    if (flag === true) {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}api/user/register`, formData)
        .then((res) => {
          if (res?.data?.message == "Your account is blocked by Admin!") {
            toast.error("Your account has been blocked");
            setLoading(false);
          }
          if (res.data.status === "failed") {
            toast.error(res?.data?.message);
            setLoading(false);
          }

          if (res.data.status === "success") {
            toast.success(res?.data?.message);
            navigate("/sign-in");
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            (err.response.data.error =
              "A two digit day could not be found Not enough data available to satisfy format")
          ) {
            inputRefs.dob.day?.current.focus();
            inputRefs.dob.day?.current.scrollIntoView({
              block: "nearest",
              behavior: "smooth",
            });
            toast.error("Birthday should be in number!");
          } else {
            toast.error("There is something went wrong!");
          }
        });
    } else {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}api/update/user/profile`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          dispatch(
            updateUser({
              userData: response.data.user,
            })
          );
          toast.success("User updated successfully");
          setLoading(false);
          addUserToFirestore(
            response?.data?.user?.id,
            response?.data?.user?.profession,
            response?.data?.user?.image
          );
          navigate("/user-profile");
        })
        .catch((err) => {
          setLoading(false);
          toast.error("There is something went wrong!");
        });
    }
  }

  async function getSects(e) {
    if (e.target.name === "religion") {
      error.religion = "";
    }
    onChange(e);
    let id = e.target.value;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/user/sects/data/${id}`
      );

      onChange({
        target: {
          name: "sects",
          value: response.data.sects,
        },
      });
    } catch (error) {}
  }

  function changer(e) {
    let name = e.target.name;

    if (e.target.name === "hobbies") {
      const value = e.target.value;

      if (value) {
        if (!selectedOptions.includes(value)) {
          // Add the selected value if it's not already in the list
          selectedOptions.push(value);
        }
      } else {
        // If the value is empty, handle removal
        const index = selectedOptions.indexOf(value);
        if (index > -1) {
          selectedOptions.splice(index, 1);
        }
      }
    }

    if (e.target.name === "day") {
      error.dob.day = "";
    } else {
      setError((prev) => {
        return { ...prev, [name]: "required" };
      });
    }

    if (e.target.name === "month") {
      error.dob.month = "";
    } else {
      setError((prev) => {
        return { ...prev, [name]: "required" };
      });
    }

    if (e.target.name === "year") {
      error.dob.year = "";
    } else {
      setError((prev) => {
        return { ...prev, [name]: "required" };
      });
    }

    if (e.target.value) {
      setError((prev) => {
        return { ...prev, [name]: "" };
      });
    } else {
      setError((prev) => {
        return { ...prev, [name]: "required" };
      });
    }
    onChange(e);
  }

  const [userData, setUserData] = useState({});

  async function fetchUserData() {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/user/signup/data`
      );
      setUserData(() => {
        return response.data;
      });
    } catch (error) {
      if (error?.message === "Network Error") {
        toast.error("Check your internet connection");
      }
    }
  }
  let [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    gender: "",
    country: "",
    nativeCountry: "",
    religion: "",
    ethnicity: "",
    cast: "",
    sect: "",
    userPass: "",
    confirmPassword: "",
    dob: {
      day: "",
      month: "",
      year: "",
    },
  });

  let inputRefs = {
    name: useRef(),
    email: useRef(),
    phone: useRef(),
    gender: useRef(),
    nativeCountry: useRef(),
    country: useRef(),
    religion: useRef(),
    ethnicity: useRef(),
    cast: useRef(),
    sect: useRef(),
    userPass: useRef(),
    confirmPassword: useRef(),
    dob: {
      day: useRef(),
      month: useRef(),
      year: useRef(),
    },
  };

  const formSubmission = (event) => {
    event.preventDefault();
    let newErrors = {
      dob: {},
    };
    let a = null;

    Object.keys(data).forEach((key) => {
      if (key === "dob") {
        Object.keys(data.dob).forEach((nestedKey) => {
          if (!data.dob[nestedKey] && flag == true) {
            if (nestedKey == "year") {
              newErrors.dob[nestedKey] = "Birth year is required";
              if (a === null) {
                a = nestedKey;
              }
            }
            if (nestedKey === "day") {
              newErrors.dob[nestedKey] = "Birthday is required";
              if (a === null) {
                a = nestedKey;
              }
            }
            if (nestedKey === "month") {
              newErrors.dob[nestedKey] = "Birth month is required";
              if (a === null) {
                a = nestedKey;
              }
            }
          }
        });
      } else if (
        key !== "nativeState" &&
        key !== "nativeCity" &&
        key !== "state" &&
        key !== "city" &&
        key !== "home" &&
        key !== "cast" &&
        key !== "image" &&
        key !== "children" &&
        key !== "financialStatus" &&
        key !== "highestQualification" &&
        key !== "profession" &&
        key !== "height" &&
        key !== "homeSize" &&
        key !== "maritalStatus" &&
        (data[key] === "" || !data[key])
      ) {
        newErrors[key] = `${key} is required`;
        if (a === null) {
          a = key;
        }
      }
    });

    if (a) {
      if (a === "month" || a === "year" || a === "day") {
        inputRefs.dob[a]?.current.focus();
        inputRefs.dob[a]?.current.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
        return;
      }
      inputRefs[a]?.current?.focus();
      inputRefs[a]?.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }

    setError((prev) => {
      return {
        ...prev,
        ...newErrors,
      };
    });

    const areAllErrorsEmpty = (obj) => {
      return Object.values(obj).every((value) => {
        if (typeof value === "object" && value !== null) {
          return areAllErrorsEmpty(value);
        }

        return value === "";
      });
    };

    if (areAllErrorsEmpty(newErrors)) {
      if (data.userPass === data.confirmPassword) {
        formSubmit();
      } else {
        toast.error("Password and Confirm Password should match!");
      }
    }
  };

  useEffect(() => {
    if (data.religion) {
      getSects({
        target: {
          value: data.religion,
          name: "religion",
        },
      });
    }
    fetchUserData();
  }, []);

  const [selectedOptions, setSelectedOptions] = useState(
    data?.hobbies ? data.hobbies : []
  );

  const handleInputClick = () => {
    setDisable(!disable);
  };

  const handleSelectChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedOptions(selectedValues);
    changer({
      target: {
        name: "hobbies",
        value: selectedValues,
      },
    });
  };

  const handleClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setDisable(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Your Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              className="form-control"
              value={data.name}
              onChange={changer}
              name="name"
              ref={inputRefs.name}
            />
          </div>
          {error.name && (
            <p className="small mb-0 text-danger mt-1">Name is required</p>
          )}
        </div>
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Gender</label>
            <select
              className="form-select"
              value={data.gender}
              onChange={changer}
              name="gender"
              ref={inputRefs.gender}
            >
              <option value="" defaultValue disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          {error.gender && (
            <p className="small mb-0 text-danger mt-1">Gender is required</p>
          )}
        </div>
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              className="form-control"
              value={data?.email}
              onChange={changer}
              name="email"
              ref={inputRefs.email}
            />
          </div>
          {error.email && (
            <p className="small mb-0 text-danger mt-1">Email is required</p>
          )}
        </div>
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Phone</label>
            <input
              type="text"
              placeholder="Please enter your phone number"
              className="form-control"
              value={data?.phone}
              onChange={changer}
              name="phone"
              ref={inputRefs.phone}
            />
          </div>
          {error.phone && (
            <p className="small mb-0 text-danger mt-1">Phone is required</p>
          )}
        </div>
        <div className="col-md-6 mb-4">
          <label className="mb-2 label">Date of Birth</label>
          <div className="row justify-content-between">
            <div className="col-3 pe-0 mb-0">
              <input
                type="text"
                placeholder="DD"
                maxLength={2}
                className="form-control text-center"
                value={data?.dob.day}
                onChange={changer}
                name="day"
                ref={inputRefs.dob.day}
              />
            </div>
            <div className="col-3 px-0 mb-0">
              <input
                type="text"
                placeholder="MM"
                maxLength={2}
                className="form-control text-center"
                value={data?.dob.month}
                onChange={changer}
                name="month"
                ref={inputRefs.dob.month}
              />
            </div>
            <div className="col-5 ps-0 mb-0">
              <input
                type="text"
                placeholder="YYYY"
                maxLength={4}
                className="form-control text-center"
                value={data.dob.year}
                onChange={changer}
                name="year"
                ref={inputRefs.dob.year}
              />
            </div>

            {(error.dob.month || error.dob.day || error.dob.year) && (
              <p className="small mb-0 text-danger mt-1">
                Date of birth is required
              </p>
            )}
          </div>
        </div>
        <SelectRegion
          setError={setError}
          labels={["Native Country", "Native state", "Native City"]}
          country={data.nativeCountry}
          state={data.nativeState}
          city={data.nativeCity}
          onChange={onChange}
          names={["nativeCountry", "nativeState", "nativeCity"]}
          countryError={error.nativeCountry !== "" ? true : false}
          countryType="Native Country"
          ref={inputRefs.nativeCountry}
        />

        <SelectRegion
          setError={setError}
          labels={[
            "Country of Residence",
            "State of Residence",
            "City of Residence",
          ]}
          country={data.country}
          state={data.state}
          city={data?.city}
          onChange={onChange}
          names={["country", "state", "city"]}
          countryError={error.country !== "" ? true : false}
          countryType="Country"
          ref={inputRefs.country}
        />
        {flag === false && (
          <>
            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <label className="mb-2 label">Height</label>

                <select
                  className="form-select"
                  value={data?.height}
                  onChange={changer}
                  name="height"
                >
                  <option value="" defaultValue disabled>
                    Select Height
                  </option>
                  {Array.from({ length: (7 - 3) * 12 + 1 }, (_, i) => {
                    const totalFeet = Math.floor(i / 12) + 3; // Calculate the feet (3 to 7)
                    const totalInches = i % 12; // Calculate the inches (0 to 11)
                    const height =
                      totalInches === 0
                        ? `${totalFeet} feet`
                        : `${totalFeet}.${totalInches}  feet`; // Format the height without .0
                    return (
                      <option key={height} value={height}>
                        {height}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <label className="mb-2 label">Highest Qualification</label>

                <select
                  className="form-select"
                  value={data?.highestQualification}
                  onChange={changer}
                  name="highestQualification"
                >
                  <option value="" disabled>
                    Select Your Qualification
                  </option>

                  {qualification
                    // Filter out duplicate categories by using the unique `id` of each category.
                    .filter(
                      (category, index, self) =>
                        index ===
                        self.findIndex((cat) => cat.id === category.id)
                    )
                    .map((category) => (
                      <optgroup
                        key={category.id}
                        label={category.name}
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {category.degree.map((degreeItem) => (
                          <option key={degreeItem.id} value={degreeItem.degree}>
                            {degreeItem.degree}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <label className="mb-2 label">Profession</label>

                <select
                  className="form-select"
                  value={data?.profession}
                  onChange={changer}
                  name="profession"
                >
                  <option value="" disabled>
                    Select Your Profession
                  </option>

                  {professions
                    .filter((profession) => profession.status === "1") // Filtering active professions with status '1'
                    .map((profession) => (
                      <option key={profession.id} value={profession.name}>
                        {profession.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <label className="mb-2 label">Financial Status</label>
                <select
                  className="form-select"
                  value={data?.financialStatus}
                  onChange={changer}
                  name="financialStatus"
                >
                  <option value="" defaultValue disabled>
                    Select Your Financial Status
                  </option>
                  <option value="upper class">Upper Class</option>
                  <option value="middle class">Middle Class</option>
                  <option value="lower class">Lower Class</option>
                </select>
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <label className="mb-2 label">Home Status</label>
                <select
                  className="form-select"
                  value={data?.home}
                  onChange={changer}
                  name="home"
                >
                  <option value="" defaultValue disabled>
                    Select
                  </option>
                  <option value="Own Home">Own Home</option>
                  <option value="Rented">Rented</option>
                  <option value="Shared Accommodation">
                    Shared Accommodation
                  </option>
                  <option value="Company Allocated">Company Allocated</option>
                </select>
              </div>
            </div>

            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <label className="mb-2 label">Home Size</label>

                <select
                  className="form-select"
                  value={data?.homeSize}
                  onChange={changer}
                  name="homeSize"
                >
                  <option value="" defaultValue disabled>
                    Select Home Size
                  </option>
                  <option value="Less then 3 marla (820 sq feet /100 Gazz)">
                    Less then 3 marla (820 sq feet /100 Gazz)
                  </option>
                  <option value="3-4 marla (1000 sq feet / 120 Gazz)">
                    3-4 marla (1000 sq feet / 120 Gazz)
                  </option>
                  <option value="5-6 marla (1500 sq feet / 160 Gazz)">
                    5-6 marla (1500 sq feet / 160 Gazz)
                  </option>
                  <option value="11-15 marla (3500 sq feet / 400 Gazz)">
                    11-15 marla (3500 sq feet / 400 Gazz)
                  </option>
                  <option value="1 kanal (5445 sq feet / 610 Gazz)">
                    1 kanal (5445 sq feet / 610 Gazz)
                  </option>
                  <option value="2 kanal (10890 sq feet / 1220 Gazz)">
                    2 kanal (10890 sq feet / 1220 Gazz)
                  </option>
                </select>
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <label className="mb-2 label">Martial Status</label>
                <select
                  className="form-select"
                  value={data.maritalStatus}
                  onChange={changer}
                  name="maritalStatus"
                >
                  <option value="" defaultValue disabled>
                    Select Marital Status
                  </option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorce">Divorce</option>
                </select>
              </div>
            </div>
            {(data?.maritalStatus === "divorce" ||
              data?.maritalStatus === "married") && (
              <div className="col-sm-6 mb-4">
                <div className="form-group">
                  <label className="mb-2 label">Number of Children</label>

                  <select
                    className="form-select"
                    value={data?.children}
                    onChange={changer}
                    name="children"
                  >
                    <option value="" defaultValue disabled>
                      Select No. of Children
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                  </select>
                </div>
              </div>
            )}
            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <label className="mb-2 label">Hobbies</label>
                <input
                  type="text"
                  value={selectedOptions}
                  // Display selected values as comma-separated
                  className="form-control"
                  name="hobbiesInput"
                  onClick={handleInputClick}
                  ref={containerRef}
                  placeholder="Hold Ctrl key and Select hobbies"
                  readOnly
                />
                <div className="position-relative">
                  {disable && (
                    <select
                      ref={containerRef}
                      className="form-select"
                      value={selectedOptions} // Should be an array
                      onChange={handleSelectChange}
                      name="hobbies"
                      multiple
                      style={{
                        position: "absolute",
                        zIndex: 1000,
                        width: "100%",
                        top: "100%",
                        left: 0,
                        height: "200px",
                        overflowY: "auto",
                        padding: "0px",
                      }}
                    >
                      {options.map((e, index) => (
                        <option
                          value={e.hobby}
                          key={index}
                          className="mb-1 py-1 ps-3"
                        >
                          {e.hobby}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Ethnicity</label>
            <select
              className="form-select"
              value={data.ethnicity}
              onChange={changer}
              name="ethnicity"
              ref={inputRefs.ethnicity}
            >
              <option value="" defaultValue disabled>
                Select Ethnicity
              </option>
              {userData.ethnicity?.map((eth, index) => (
                <option value={eth.name} key={index}>
                  {eth.name}
                </option>
              ))}
            </select>
          </div>
          {error.ethnicity && (
            <p className="small mb-0 text-danger mt-1">Ethnicity is required</p>
          )}
        </div>
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Religion</label>
            <select
              className="form-select"
              onChange={getSects}
              name="religion"
              value={data.religion}
              ref={inputRefs.religion}
            >
              <option value="" defaultValue disabled>
                Select Religion
              </option>
              {userData.religions?.map((religion, index) => {
                return (
                  <option value={religion.id} key={index}>
                    {religion.religion}
                  </option>
                );
              })}
            </select>
          </div>
          {error.religion && (
            <p className="small mb-0 text-danger mt-1">Religion is required</p>
          )}
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className={`${cast ? "col-6" : "col-12"} mb-4`}>
              <label className="mb-2 label">Sect</label>
              <select
                className="form-select"
                value={data.sect}
                onChange={changer}
                name="sect"
              >
                <option value="" defaultValue disabled>
                  Select Sect
                </option>
                {data.sects?.map((sect, index) => (
                  <option key={index + sect.name}>{sect.name}</option>
                ))}
              </select>
              {error.sect && (
                <p className="small mb-0 text-danger mt-1">Sect is required</p>
              )}
            </div>
            {cast && (
              <div className="col-6 mb-4">
                <label className="mb-2 label">Cast</label>
                <select
                  className="form-select"
                  value={data.cast}
                  onChange={changer}
                  name="cast"
                >
                  <option value="" defaultValue disabled>
                    Select Cast
                  </option>
                  {userData.casts?.map((cast, index) => (
                    <option value={cast.name} key={index}>
                      {cast.name}
                    </option>
                  ))}
                </select>
                {error.cast && (
                  <p className="small mb-0 text-danger mt-1">
                    Cast is required
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        {flag && (
          <>
            {" "}
            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <PasswordInput
                  label={"Password"}
                  placeholder={"Enter your password"}
                  value={data?.userPass}
                  onChange={changer}
                  name="userPass"
                  reference={inputRefs.userPass}
                />
              </div>
              {error.userPass && (
                <p className="small mb-0 text-danger mt-1">
                  Password is required
                </p>
              )}
            </div>
            <div className="col-sm-6 mb-4">
              <div className="form-group">
                <PasswordInput
                  label={"Confirm Password"}
                  placeholder={"Enter confirm password"}
                  name="confirmPassword"
                  value={data?.confirmPassword}
                  onChange={changer}
                  reference={inputRefs.confirmPassword}
                />
              </div>
              {error.confirmPassword && (
                <p className="small mb-0 text-danger mt-1">
                  Confirm Password is required
                </p>
              )}
            </div>
          </>
        )}
      </div>
      {checked && (
        <div className="d-flex align-items-center gap-2 small">
          <Form.Check // prettier-ignore
            type="checkbox"
            id={`check`}
            onChange={() => {
              setCheck(!check);

              setCheckError(false);
            }}
            className="checkbox-check"
          />
          {/* <input
            type="checkbox"
            name="check"
            id="check"
            
          /> */}
          <label htmlFor="check" className="pointer ">
            I agree to{" "}
            <Link
              style={{ color: "#222344" }}
              to="/privacy-policy"
              className="fw-medium"
            >
              Privacy Policy
            </Link>{" "}
            and{" "}
            <Link
              style={{ color: "#222344" }}
              to="/terms-&-conditions"
              className="fw-medium"
            >
              Terms & Conditions
            </Link>
          </label>
        </div>
      )}
      {checkError && (
        <p className="small mb-0 text-danger mt-1 ">
          Do you agree to the terms and conditions?
        </p>
      )}

      {flag ? (
        <div className="text-center">
          <button
            disabled={loading}
            className={`btn auth-btn clr-btn-theme register-btn py-2 mt-5 mb-2 ${
              loader ? "bg-white" : ""
            }`}
            onClick={formSubmission}
          >
            {loading || loader ? (
              <Loading stroke="4" width="20" height="20" />
            ) : (
              "Register"
            )}
          </button>
        </div>
      ) : (
        <div className="d-flex justify-content-center mt-4">
          <button
            disabled={loading}
            onClick={formSubmission}
            className="btn auth-btn clr-btn-theme register-btn py-2"
          >
            {loading ? <Loading stroke="4" width="20" height="20" /> : "Update"}
          </button>
        </div>
      )}
    </>
  );
}

export { EditUpdate };
