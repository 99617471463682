import { useEffect, useState } from "react";
import MyInfo from "../Components/MyInfo";
import Passions from "../Components/Passions";
import UserProfiles from "../Components/UserProfiles.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../Components/Loading.js";
import { logOutUser } from "../store/authSlices.js";
import Download from "../Components/Download.js";
const Wishlist = () => {
  const { user, token, login } = useSelector((state) => {
    return state.user;
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchingData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL +
            `api/get/user/wishlist/members/${user?.id}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        if (error?.response?.data?.message == "Token has expired") {
          toast.error("Your token has been expired again sign in");
          dispatch(logOutUser());
          return;
        }
        if (error?.message === "Network Error" && login == true) {
          toast.error("Check your internet Connection");
        }
        setLoading(false);
      }
    };
    fetchingData();
  }, []);

  return (
    <div className="container-fluid px-xl-5 px-sm-4">
      <div className="row mx-0">
        <div className="col-xl-3 px-xl-2 p-0">
          <MyInfo />
        </div>
        <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
          <div className="p-3 background-container">
            {login ? (
              loading ? (
                <Loading stroke="4" />
              ) : data.length === 0 ? (
                <p className="text-center text-light p-0 m-0">
                  No profile added to wishlist
                </p>
              ) : (
                <UserProfiles setData={setData} data={data} flag={true} />
              )
            ) : (
              <p className="text-center text-light p-0 m-0">
                Sign in to see wishlist profiles
              </p>
            )}
          </div>
        </div>
        <div className="col-xl-3 px-xl-2 p-0">
          <Passions />
          <Download />
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
