import React, { useEffect, useRef, useState } from "react";
import SelectRegion from "../Components/SelectRegion";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../store/authSlices";
import Loading from "../Components/Loading";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../Components/Firebase";

export default function SignUpWithGoogle() {
  let [data, setData] = useState({
    phone: "",
    gender: "",
    religion: "",
    sects: [],
    sect: "",
    country: "",
    nativeCountry: "",
    profession: "",
    ethnicity: "",
  });

  function onChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    setData((prev) => {
      return { ...prev, [name]: value };
    });
  }

  return (
    <div className="py-5 px-4 container-fluid register-form forms">
      <div className="row">
        <div className="col-xl-9 col-lg-10 mx-auto">
          <div
            className="shadow bg-white rounded-2 pb-5"
            style={{ overflow: "hidden" }}
          >
            <div className="form-header position-relative py-4 ps-3 ps-sm-4 ps-lg-5">
              <h4 className="text-white py-3 mb-0">Required Details</h4>
              <div className="title">
                <div className="PolygonRuler">
                  <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="PolygonRuler__SVG"
                  >
                    <polygon
                      points="2560 0 2560 100 0 100"
                      className="PolygonRuler__Polygon PolygonRuler__Polygon--fill-white"
                    ></polygon>
                  </svg>
                </div>
              </div>
            </div>
            <form>
              <div className="px-3 px-sm-4 px-lg-5  mt-4">
                <p className="small-heading mb-4">
                  Enter the following details
                </p>
                <EditUpdate1 data={data} onChange={onChange} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function EditUpdate1({ data, onChange, formSubmit }) {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profession, setProfession] = useState([]);

  const email = localStorage.getItem("email");
  const name = localStorage.getItem("name");
  const social_id = localStorage.getItem("social_id");
  const id = localStorage.getItem("id");
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const getUser = async () => {
      axios
        .get(process.env.REACT_APP_API_URL + `api/get/user/profile/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          setProfession((prev) => [...prev, ...res?.data?.professions]);
        })
        .catch(() => {
          toast.error("There is something went wrong!");
        });
    };
    getUser();
  }, []);

  const addUserToFirestore = async (userId, userName, profession, image) => {
    try {
      const userDoc = {
        id: userId,
        name: userName,
        profession: profession,
        image: image,
      };
      await setDoc(doc(db, "users", userId?.toString()), userDoc);
    } catch (error) {}
  };

  async function formSubmit() {
    let religionObj = userData.religions.find((elem) => {
      return elem.id == data.religion;
    });

    let countryName = data.country.split(",,");
    let nativeCountryName = data.nativeCountry.split(",,");

    const formDataObject = {
      name: name,
      email: email,
      social_id: social_id,
      phone: data?.phone,
      gender: data?.gender?.toLowerCase(),
      religion: religionObj?.religion,
      religion_id: religionObj?.id,
      profession: data?.profession,
      sect: data?.sect,
      ethnicity: data?.ethnicity,
      country_of_residence: countryName[1],
      native_country: nativeCountryName[1],
      user_id: data?.id ? data.id : undefined,
      country_of_residence_code: countryName[0],
      native_country_code: nativeCountryName[0],
      login_type: "google",
      fcm_token: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjZjYzN",
    };

    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}api/socialLogin`, formDataObject)
      .then((res) => {
        if (res.data.message === "User Login Successfully!") {
          toast.success(res?.data?.message);
          dispatch(
            loginUser({
              userData: res?.data?.user,
              token: res?.data?.access_token,
            })
          );

          navigate("/");

          setLoading(false);
          addUserToFirestore(
            id,
            name,
            res?.data?.user?.profession,
            res?.data?.user?.image
          );
          localStorage.clear();
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("There is something went wrong!");
      });
  }

  async function getSects(e) {
    if (e.target.name === "religion") {
      error.religion = "";
    }
    onChange(e);
    let id = e.target.value;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/user/sects/data/${id}`
      );

      onChange({
        target: {
          name: "sects",
          value: response.data.sects,
        },
      });
    } catch (error) {}
  }

  function changer(e) {
    let name = e.target.name;

    if (e.target.name === "day") {
      error.dob.day = "";
    } else {
      setError((prev) => {
        return { ...prev, [name]: "required" };
      });
    }

    if (e.target.name === "month") {
      error.dob.month = "";
    } else {
      setError((prev) => {
        return { ...prev, [name]: "required" };
      });
    }

    if (e.target.name === "year") {
      error.dob.year = "";
    } else {
      setError((prev) => {
        return { ...prev, [name]: "required" };
      });
    }

    if (e.target.value) {
      setError((prev) => {
        return { ...prev, [name]: "" };
      });
    } else {
      setError((prev) => {
        return { ...prev, [name]: "required" };
      });
    }
    onChange(e);
  }

  const [userData, setUserData] = useState({});

  async function fetchUserData() {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/user/signup/data`
      );
      setUserData(() => {
        return response.data;
      });
    } catch (error) {
      if (error?.message === "Network Error") {
        toast.error("Check your internet connection");
      }
    }
  }
  let [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    gender: "",
    country: "",
    nativeCountry: "",
    religion: "",
    sect: "",
    profession: "",
  });

  let inputRefs = {
    name: useRef(),
    email: useRef(),
    phone: useRef(),
    gender: useRef(),
    nativeCountry: useRef(),
    country: useRef(),
    religion: useRef(),
    sect: useRef(),
    profession: useRef(),
  };

  const formSubmission = (event) => {
    event.preventDefault();
    let newErrors = {
      dob: {},
    };
    let a = null;

    Object.keys(data).forEach((key) => {
      if (key === "dob") {
        Object.keys(data.dob).forEach((nestedKey) => {
          if (!data.dob[nestedKey]) {
            if (nestedKey == "year") {
              newErrors.dob[nestedKey] = "Birth year is required";
              if (a === null) {
                a = nestedKey;
              }
            }
            if (nestedKey === "day") {
              newErrors.dob[nestedKey] = "Birthday is required";
              if (a === null) {
                a = nestedKey;
              }
            }
            if (nestedKey === "month") {
              newErrors.dob[nestedKey] = "Birth month is required";
              if (a === null) {
                a = nestedKey;
              }
            }
          }
        });
      } else if (
        key !== "nativeState" &&
        key !== "nativeCity" &&
        key !== "state" &&
        key !== "city" &&
        (data[key] === "" || !data[key])
      ) {
        newErrors[key] = `${key} is required`;
        if (a === null) {
          a = key;
        }
      }
    });

    if (a) {
      if (a === "month" || a === "year" || a === "day") {
        inputRefs.dob[a]?.current.focus();
        inputRefs.dob[a]?.current.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
        return;
      }
      inputRefs[a]?.current?.focus();
      inputRefs[a]?.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }

    setError((prev) => {
      return {
        ...prev,
        ...newErrors,
      };
    });

    const areAllErrorsEmpty = (obj) => {
      return Object.values(obj).every((value) => {
        if (typeof value === "object" && value !== null) {
          return areAllErrorsEmpty(value);
        }

        return value === "";
      });
    };

    if (areAllErrorsEmpty(newErrors)) {
      formSubmit();
    }
  };

  useEffect(() => {
    if (data.religion) {
      getSects({
        target: {
          value: data.religion,
          name: "religion",
        },
      });
    }
    fetchUserData();
  }, []);

  const handleClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setDisable(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Gender</label>
            <select
              className="form-select"
              value={data.gender}
              onChange={changer}
              name="gender"
              ref={inputRefs.gender}
            >
              <option value="" defaultValue disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          {error.gender && (
            <p className="small mb-0 text-danger mt-1">Gender is required</p>
          )}
        </div>
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Phone</label>
            <input
              type="text"
              placeholder="Please enter your phone number"
              className="form-control"
              value={data?.phone}
              onChange={changer}
              name="phone"
              ref={inputRefs.phone}
            />
          </div>
          {error.phone && (
            <p className="small mb-0 text-danger mt-1">Phone is required</p>
          )}
        </div>
        <SelectRegion
          setError={setError}
          labels={["Native Country"]}
          country={data.nativeCountry}
          names={["nativeCountry"]}
          onChange={onChange}
          flag1={false}
          countryError={error.nativeCountry !== "" ? true : false}
          countryType="Native Country"
          ref={inputRefs.nativeCountry}
        />
        <SelectRegion
          flag1={false}
          setError={setError}
          labels={["Country of Residence"]}
          country={data.country}
          state={data.state}
          city={data?.city}
          onChange={onChange}
          names={["country"]}
          countryError={error.country !== "" ? true : false}
          countryType="Country"
          ref={inputRefs.country}
        />
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Ethnicity</label>
            <select
              className="form-select"
              value={data.ethnicity}
              onChange={changer}
              name="ethnicity"
              ref={inputRefs.ethnicity}
            >
              <option value="" defaultValue disabled>
                Select Ethnicity
              </option>
              {userData.ethnicity?.map((eth, index) => (
                <option value={eth.name} key={index}>
                  {eth.name}
                </option>
              ))}
            </select>
          </div>
          {error.ethnicity && (
            <p className="small mb-0 text-danger mt-1">Ethnicity is required</p>
          )}
        </div>
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Religion</label>
            <select
              className="form-select"
              onChange={getSects}
              name="religion"
              value={data.religion}
              ref={inputRefs.religion}
            >
              <option value="" defaultValue disabled>
                Select Religion
              </option>
              {userData.religions?.map((religion, index) => {
                return (
                  <option value={religion.id} key={index}>
                    {religion.religion}
                  </option>
                );
              })}
            </select>
          </div>
          {error.religion && (
            <p className="small mb-0 text-danger mt-1">Religion is required</p>
          )}
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-12 mb-4">
              <label className="mb-2 label">Sect</label>
              <select
                className="form-select"
                value={data.sect}
                onChange={changer}
                name="sect"
              >
                <option value="" defaultValue disabled>
                  Select Sect
                </option>
                {data.sects?.map((sect, index) => (
                  <option key={index + sect.name}>{sect.name}</option>
                ))}
              </select>
              {error.sect && (
                <p className="small mb-0 text-danger mt-1">Sect is required</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-6 mb-4">
          <div className="form-group">
            <label className="mb-2 label">Profession</label>

            <select
              className="form-select"
              value={data?.profession}
              onChange={changer}
              name="profession"
            >
              <option value="" disabled>
                Select Your Profession
              </option>

              {profession
                .filter((profession) => profession.status === "1") // Filtering active professions with status '1'
                .map((profession) => (
                  <option key={profession.id} value={profession.name}>
                    {profession.name}
                  </option>
                ))}
            </select>
            {error.profession && (
              <p className="small mb-0 text-danger mt-1">
                Profession is required
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="text-center">
        <button
          disabled={loading}
          className="btn auth-btn clr-btn-theme register-btn py-2 mt-5 mb-2"
          onClick={formSubmission}
        >
          {loading ? <Loading stroke="4" width="20" height="20" /> : "Done"}
        </button>
      </div>
    </>
  );
}
