import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import '../modal.css'
export default function FormModal({className,children,show,handleClose}) {
 
  return (
     <Modal show={show} onHide={handleClose} className={`${className} form-modal`} centered>
        <Modal.Body className='p-0'>
          {children}
         </Modal.Body>
     </Modal>    
   
  )
}

