import React from "react";

function NotFound() {
  return (
    <div
      style={{ height: "60vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <p className="h3 text-light fw-medium">Page not Found</p>
    </div>
  );
}

export default NotFound;
