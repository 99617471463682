import React, { useEffect, useState, forwardRef } from "react";

const SelectRegion = forwardRef(
  (
    {
      country,
      state,
      city,
      names,
      labels,
      onChange,
      countryError,
      countryType,
      setError,
      formData,
      setFormData,
      flag = true,
      flag1 = true,
      flag2 = false,
    },
    ref
  ) => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
      fetch("https://api.countrystatecity.in/v1/countries", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSCAPI-KEY":
            "TExJVmdYa1pFcWFsRWViS0c3dDRRdTdFV3hnWXJveFhQaHoyWVo3Mw==",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setCountries(result);
          if (country) {
            const obj = result.find((e) => e.name === country);
            if (obj) {
              const event = {
                target: {
                  value: `${obj.iso2},,${country}`,
                  name: names[0],
                },
              };
              updateCountry(event, false); // Call updateCountry with an event
            }
          }
        })
        .catch((err) => {});
    }, [country]); // Add country as a dependency

    function updateCountry(e, flag = true) {
      const { name, value } = e.target;
      onChange(e);
      setError((prev) => ({ ...prev, [name]: "" }));
      if (flag == true && flag1 == true) {
        onChange({
          target: {
            name: name === "country" ? "state" : "nativeState",
            value: "",
          },
        });
        onChange({
          target: {
            name: name === "country" ? "city" : "nativeCity",
            value: "",
          },
        });
      }
      const countryCode = value.split(",,")[0];
      fetch(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSCAPI-KEY":
              "TExJVmdYa1pFcWFsRWViS0c3dDRRdTdFV3hnWXJveFhQaHoyWVo3Mw==",
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          setStates(result);
          if (state) {
            let stateObject = result?.find((e) => {
              return e.name === state;
            });
            if (stateObject) {
              updateState(
                {
                  target: {
                    value: stateObject?.iso2 + ",," + stateObject.name,
                    name: names[1],
                  },
                },
                countryCode
              );
            }
          }
        });
    }

    function updateState(e, code) {
      const { name, value } = e?.target;
      onChange(e);
      if (!code) {
        onChange({
          target: { name: name === "state" ? "city" : "nativeCity", value: "" },
        });
      }

      const stateCode = value?.split(",,")[0];
      const countryCode = code ? code : country.split(",,")[0];
      fetch(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateCode}/cities`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSCAPI-KEY":
              "TExJVmdYa1pFcWFsRWViS0c3dDRRdTdFV3hnWXJveFhQaHoyWVo3Mw==",
          },
        }
      )
        .then((response) => response?.json())
        .then((result) => setCities(result));
    }

    return (
      <>
        <div className={`${flag ? "col-sm-6 " : "col-12"} mb-4`}>
          <div className="form-group position-relative">
            <label className="mb-2 label">{labels[0]}</label>
            <select
              className="form-select"
              value={country}
              onChange={updateCountry}
              ref={ref} // Applying ref to the select element
              name={names[0]}
            >
              <option value="" disabled>
                Select Country
              </option>
              {countries.map((country, index) => (
                <option value={`${country.iso2},,${country.name}`} key={index}>
                  {country.name}
                </option>
              ))}
            </select>
            {flag2 && formData[names[0]] && formData[names[0]] !== "" && (
              <span
                onClick={() => {
                  setFormData((prev) => ({
                    ...prev,
                    [names[0]]: "", // Reset country
                  }));
                }}
                style={{ top: "63%", right: "14%" }}
                className="fa-solid fa-xmark text-danger position-absolute"
              ></span>
            )}
          </div>
          {countryError && (
            <p className="small mb-0 text-danger mt-1">
              {countryType} is required
            </p>
          )}
        </div>
        {flag1 == true && (
          <div className={`${flag ? "col-sm-6 " : "col-12"} mb-4`}>
            <div className="form-group position-relative">
              <label className="mb-2 label">{labels[1]}</label>
              <select
                className="form-select"
                value={state}
                name={names[1]}
                onChange={updateState}
              >
                <option value="" defaultValue disabled selected>
                  Select State
                </option>
                {states?.length ? (
                  states?.map((state, index) => (
                    <option value={state.iso2 + ",," + state.name} key={index}>
                      {state.name}
                    </option>
                  ))
                ) : (
                  <option value="" defaultValue disabled selected>
                    No States Available
                  </option>
                )}
              </select>
              {flag2 && formData[names[1]] && formData[names[1]] !== "" && (
                <span
                  onClick={() => {
                    setFormData((prev) => ({
                      ...prev,
                      [names[1]]: "", // Reset country
                    }));
                  }}
                  style={{ top: "63%", right: "14%" }}
                  className="fa-solid fa-xmark text-danger position-absolute"
                ></span>
              )}
            </div>
          </div>
        )}
        {flag1 == true && (
          <div className={`${flag ? "col-sm-6 " : "col-12"} mb-4`}>
            <div className="form-group position-relative">
              <label className="mb-2 label">{labels[2]}</label>
              <select
                className="form-select"
                value={city}
                name={names[2]}
                onChange={onChange}
              >
                <option value="" defaultValue disabled selected>
                  Select City
                </option>
                {cities.length ? (
                  cities.map((city, index) => (
                    <option key={index} value={city.name}>
                      {city.name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No Cities Available
                  </option>
                )}
              </select>
              {flag2 && formData[names[2]] && formData[names[2]] !== "" && (
                <span
                  onClick={() => {
                    setFormData((prev) => ({
                      ...prev,
                      [names[2]]: "",
                    }));
                  }}
                  style={{ top: "63%", right: "14%" }}
                  className="fa-solid fa-xmark text-danger position-absolute"
                ></span>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
);

export default SelectRegion;
