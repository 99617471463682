import MyInfo from "../Components/MyInfo";
import Passions from "../Components/Passions";
import OtherPages from "../Components/OtherPages";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Ads from "../Components/Ads";
import Loading from "../Components/Loading";
import { logOutUser } from "../store/authSlices";
import { toast } from "react-toastify";
import Download from "../Components/Download";

const AdsListing = () => {
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  const state = useSelector((state) => {
    return state.user;
  });

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [post, setPost] = useState([]);
  const ref = useRef();

  useEffect(() => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}api/user/get/ads?page=${page}`)
      .then((response) => {
        setPost((prev) => [...prev, ...response.data.data]);
        setLoading(false);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message == "Token has expired") {
          toast.error("Your token has been expired again sign in");
          dispatch(logOutUser());
          return;
        }
        setLoading(false);
      });
  }, [page]);

  const changeSkip = () => {
    clearTimeout(ref.current); // Clear the existing timeout

    if (loader || !hasMore) return; // Prevent unnecessary requests

    let height = window.innerHeight;
    let scrollHeight = document.documentElement.scrollHeight;
    let scrollPosition = document.documentElement.scrollTop;

    // Check if the user has reached near the bottom of the page
    if (height + scrollPosition + 1 >= scrollHeight) {
      ref.current = setTimeout(() => {
        setPage((prev) => prev + 1); // Load the next page
      }, 500); // Debounce to 500ms
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeSkip);

    return () => {
      window.removeEventListener("scroll", changeSkip); // Cleanup
    };
  }, [loader, hasMore]);

  return (
    <div ref={ref} className="container-fluid px-xl-5 px-sm-4">
      <div className="row mx-0">
        <div className="col-xl-3 px-xl-2 p-0">
          <MyInfo />
        </div>
        <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
          <div className="p-3 background-container">
            {loading ? <Loading stroke="4" /> : <Ads data={post} />}
            {loader == true && loading == false && (
              <Loading height={30} width={30} />
            )}
          </div>
        </div>
        <div className="col-xl-3 px-xl-2 p-0">
          <Passions />
          <Download />
        </div>
      </div>
    </div>
  );
};

export default AdsListing;
