import React, { useEffect, useState } from "react";
import Passions from "../Components/Passions";
import MyInfo from "../Components/MyInfo";
import Accordion from "react-bootstrap/Accordion";
import "../faq.css";
import axios from "axios";
import { toast } from "react-toastify";
import Download from "../Components/Download";

export default function Faq() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/faqs`)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          toast.error("Check your internet connection");
        }
      });
  }, []);
  return (
    <div className="container-fluid px-xl-5 px-sm-4">
      <div className="row mx-0">
        <div className="col-xl-3 px-xl-2 p-0">
          <MyInfo />
        </div>
        <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
          <div className="p-3 background-container">
            <h4 style={{ color: "#222344" }} className="theme">
              FAQ's
            </h4>
            <div className="mt-3">
              <Accordion defaultActiveKey="0">
                {data?.map((e, index) => {
                  return (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>{e?.questions}</Accordion.Header>
                      <Accordion.Body
                        dangerouslySetInnerHTML={{
                          __html: e?.answers,
                        }}
                      ></Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
            <div className="d-flex flex-column mt-4">
              <p style={{ fontSize: "0.8rem" }} className="m-0 p-0 text-center">
                If you still have any queries, feel free to contact us at:
              </p>
              <a
                target="_blank"
                href="mailto:urgentmarriageapp@gmail.com"
                style={{ fontSize: "0.8rem", color: "#222344" }}
                className="mt-0 p-0"
              >
                <p className="text-center">urgentmarriageapp@gmail.com</p>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-3 px-xl-2 p-0">
          <Passions />
          <Download />
        </div>
      </div>
    </div>
  );
}
