import { Oval } from "react-loader-spinner";

export default function Loading({ height, width, stroke, color = "#222344" }) {
  return (
    <div className="d-flex justify-content-center bg-light bg-opacity-25">
      <Oval
        height={height}
        width={width}
        radius="9"
        color={color}
        ariaLabel="loading"
        wrapperclassName="spinner"
        secondaryColor={color}
        strokeWidth={stroke}
      />
    </div>
  );
}
