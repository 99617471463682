import React, { useEffect, useState } from "react";
import MyInfo from "../Components/MyInfo";
import Passions from "../Components/Passions";
import axios from "axios";
import { toast } from "react-toastify";
import Download from "../Components/Download";

function PrivacyPolicy() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/privacyPolicy`)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          toast.error("Check your internet Connection");
        }
      });
  }, []);
  return (
    <div className="container-fluid px-xl-5 px-sm-4">
      <div className="row mx-0">
        <div className="col-xl-3 px-xl-2 p-0">
          <MyInfo />
        </div>
        <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
          <div className="p-3 background-container">
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.description
                    ? data?.description
                    : "No content available",
                }}
              />
            </p>
          </div>
        </div>
        <div className="col-xl-3 px-xl-2 p-0">
          <Passions />
          <Download />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
