import React, { useState } from "react";
const PasswordInput = React.forwardRef((props) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <label className="mb-2 label">{props.label}</label>
      <div className="password-input-container position-relative">
        <input
          type={`${show ? "text" : "password"}`}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          className="form-control"
          ref={props.reference}
        />
        <span
          onClick={() => setShow(!show)}
          className={`fa-regular eye-icon ${!show ? "fa-eye" : "fa-eye-slash"}`}
        ></span>
      </div>
    </>
  );
});

export default PasswordInput;
