import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";

function OTP() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
  });

  let inputRefs = {
    0: useRef(),
    1: useRef(),
    2: useRef(),
    3: useRef(),
  };

  const [error, setError] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
  });

  function confirmOTP() {
    let email = localStorage.getItem("email");
    let str = "";
    let hasError = false;
    let updatedError = { ...error };

    for (let k in inputRefs) {
      if (!inputRefs[k]?.current?.value) {
        updatedError[k] = "OTP is required";
        inputRefs[k]?.current?.focus();
        hasError = true;
        break;
      } else {
        updatedError[k] = ""; // Clear any existing errors
      }
      str += inputRefs[k].current.value;
    }

    setError(updatedError);

    if (hasError) {
      return; // Stop execution if there's an error
    }

    let data = { otp: str, email };

    if (!data.email) {
      setLoading(false);
      navigate("/forget-password");
      toast.error("There is something went wrong!");
    }

    if (data.otp && data.email) {
      setLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}api/user/send/OTP/verify`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) =>
          response.json().then((result) => {
            if (result.status === "failed") {
              toast.error(result.message);
              setLoading(false);
            }
            if (result.status === "success") {
              setLoading(false);
              toast.success(result.message);
              navigate("/change-password");
            }
          })
        )
        .catch(() => {
          setLoading(false);
          toast.error("There is something went wrong!");
        });
    }
  }

  function onChange(value, index) {
    let current = index === -1 ? 3 : index;
    index += 1;
    if (value) {
      if (value.length === 2) {
        if (inputRefs[current] && inputRefs[current].current) {
          inputRefs[current].current.value = value[1];
        }
      }
      if (inputRefs[index] && inputRefs[index].current) {
        inputRefs[index].current.focus();
      }
    }

    // Update the state with the new value
    setData((prev) => ({ ...prev, [current]: value }));
    setError((prev) => ({ ...prev, [current]: "" })); // Clear error when input changes
  }

  function onPaste(event) {
    let value = event.clipboardData.getData("Text");
    value = value.trim();
    if (value.length < 4) return;
    for (let i = 0; i < 4; i++) {
      inputRefs[i].current.value = value[i];
      setData((prev) => ({ ...prev, [i]: value[i] }));
    }
  }

  return (
    <div className="py-5 px-4 container-fluid otp-form forms">
      <div className="row">
        <div className="col-xl-7 col-lg-8 col-md-9 col-sm-10 mx-auto">
          <div
            className="shadow bg-white rounded-2 pb-5"
            style={{ overflow: "hidden" }}
          >
            <div className="form-header position-relative py-4 ps-3 ps-sm-4 ps-lg-5">
              <h4 className="text-white py-3 mb-0">OTP</h4>
              <div className="title">
                <div className="PolygonRuler">
                  <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="PolygonRuler__SVG"
                  >
                    <polygon
                      points="2560 0 2560 100 0 100"
                      className="PolygonRuler__Polygon PolygonRuler__Polygon--fill-white"
                    ></polygon>
                  </svg>
                </div>
              </div>
            </div>
            <div className="px-3 px-sm-4 px-lg-5 pb-5 mt-4">
              <p className="small-heading mb-4 mb-5 pb-3 pe-sm-5">
                A 4-digit pin code has been sent to your email. Please enter pin
                code to verify your account.
              </p>
              <div className="row">
                <div>
                  <div className="mb-4 d-flex gap-5 justify-content-center">
                    <input
                      ref={inputRefs[0]}
                      name="0"
                      onPaste={onPaste}
                      maxLength={2}
                      onChange={(e) => onChange(e.target.value, 0)}
                      type="text"
                      className="otp-input"
                    />
                    <input
                      ref={inputRefs[1]}
                      name="1"
                      onPaste={onPaste}
                      maxLength={2}
                      onChange={(e) => onChange(e.target.value, 1)}
                      type="text"
                      className="otp-input"
                    />
                    <input
                      ref={inputRefs[2]}
                      name="2"
                      onPaste={onPaste}
                      maxLength={2}
                      onChange={(e) => onChange(e.target.value, 2)}
                      type="text"
                      className="otp-input"
                    />
                    <input
                      ref={inputRefs[3]}
                      name="3"
                      onPaste={onPaste}
                      maxLength={2}
                      onChange={(e) => onChange(e.target.value, 3)}
                      type="text"
                      className="otp-input"
                    />
                  </div>
                  {(error[0] || error[1] || error[2] || error[3]) && (
                    <p className="small mt-2 text-danger text-center">
                      OTP is required
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-center pt-5">
                  <button
                    disabled={loading}
                    className="btn auth-btn clr-btn-theme register-btn py-2"
                    onClick={confirmOTP}
                  >
                    {loading ? (
                      <Loading stroke="4" width="20" height="20" />
                    ) : (
                      "Confirm OTP"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTP;
