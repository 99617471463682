import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const [error, setError] = useState({
    email: "",
  });
  const inputRef = {
    email: useRef(),
  };

  const onChange = (e) => {
    const value = e.target.value;

    if (value) {
      setError((prev) => {
        return { ...prev, email: "" };
      });
    } else {
      setError((prev) => {
        return { ...prev, email: "Email is required" };
      });
    }

    setEmail(value);
  };

  function sendOTP() {
    let a = null;

    if (email === "") {
      setError((prev) => {
        return {
          ...prev,
          email: "Email is required",
        };
      });
      a = "email";
    }

    if (a) {
      inputRef[a]?.current?.focus();
      inputRef[a]?.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }

    let data = { email };

    if (data.email) {
      setloading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}api/user/send/OTP`, data)
        .then((response) => {
          if (response.data.status === "failed") {
            toast.error(response.data.message);
            setloading(false);
          }
          if (response.data.status === "success") {
            localStorage.setItem("email", email);
            toast.success(response.data.message);
            navigate(`/otp`);
            setloading(false);
          }
        })
        .catch(() => {
          setloading(false);
          toast.error("There is something went wrong!");
        });
    }
  }
  return (
    <div className="py-5 px-4 container-fluid sigin-form forms">
      <div className="row">
        <div className="col-xl-7 col-lg-8 col-md-9 col-sm-1 mx-auto">
          <div
            className="shadow bg-white rounded-2 pb-5"
            style={{ overflow: "hidden" }}
          >
            <div className="form-header position-relative py-4 ps-3 ps-sm-4 ps-lg-5">
              <h4 className="text-white py-3 mb-0">Forget Password</h4>
              <div className="title">
                <div className="PolygonRuler">
                  <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="PolygonRuler__SVG"
                  >
                    <polygon
                      points="2560 0 2560 100 0 100"
                      className="PolygonRuler__Polygon PolygonRuler__Polygon--fill-white"
                    ></polygon>
                  </svg>
                </div>
              </div>
            </div>
            <div className="px-3 px-sm-4 px-lg-5 pb-5 mt-4">
              <p className="small-heading mb-4">
                Type your email to restore password
              </p>
              <div className="row">
                <div className="mb-4">
                  <div className="form-group">
                    <label className="mb-2 label">Email</label>
                    <input
                      type="email"
                      placeholder="Enter your name"
                      className="form-control"
                      name="email"
                      ref={inputRef.email}
                      value={email}
                      onChange={onChange}
                    />
                    {error.email && (
                      <p className="text-danger mt-2 small">
                        Email is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-center pt-5">
                  <button
                    disabled={loading}
                    className="btn auth-btn clr-btn-theme register-btn py-2"
                    onClick={sendOTP}
                  >
                    {loading ? (
                      <Loading stroke="4" width="20" height="20" />
                    ) : (
                      "Send OTP"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
