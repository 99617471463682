import React, { useEffect, useState } from "react";
import { EditUpdate } from "./Register";
import UserLogo from "../Components/UserLogo";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../Components/Loading";
import { logOutUser } from "../store/authSlices";
export default function EditProfile() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const state = useSelector((state) => {
    return state.user;
  });

  const [userHobbies, setUserHobbies] = useState([]);
  const [userProfessions, setUserProfession] = useState([]);
  const [userQualification, setUserQualification] = useState([]);

  const user = state?.user;

  const dateOfBirth = user?.dob || "";

  const [day, month, year] = dateOfBirth?.split("/");

  let dayVariable = null;
  let monthVariable = null;
  let yearVariable = null;

  if (day && month && year) {
    dayVariable = day;
    monthVariable = month;
    yearVariable = year;
  }

  const hobbies = user?.hobbies;

  const hobbiesString = hobbies
    ?.replace(/[\[\]"]/g, "") // Remove brackets and quotes
    ?.split(",") // Split the string by comma
    ?.map((hobby) => hobby.trim()) // Trim any extra whitespace
    ?.join(", ");

  const newHobbiesString = hobbiesString?.replace(/\\/g, "");

  let [data, setData] = useState({
    name: user?.name,
    email: user?.email,
    phone: user?.phone,
    gender: user?.gender,
    religion: parseInt(user?.religion_id),
    ethnicity: user?.ethnicity || "",
    cast: user?.cast || "",
    sects: [],
    dob: {
      day: dayVariable || null,
      month: monthVariable || null,
      year: yearVariable || null,
    },
    sect: user?.sect || "",
    country: user?.country_of_residence,
    state: user?.state_of_residence || "",
    city: user?.city_of_residence ? user.city_of_residence : "",
    nativeCountry: user?.native_country,
    nativeCity: user?.native_city ? user.native_city : "",
    nativeState: user?.native_state || "",
    id: user?.id,
    image: user?.image || null,
    financialStatus: user?.financial_status || "",
    height: user?.height || "",
    highestQualification: user?.highest_qualification || "",
    home: user?.home || "",
    homeSize: user?.home_size || "",
    maritalStatus: user?.marital_status || "",
    children: user?.no_of_childs || "",
    profession: user?.profession || "",
    hobbies: newHobbiesString || [],
  });

  function onChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "day" || name == "month" || name == "year") {
      setData((prev) => {
        return {
          ...prev,
          dob: { ...prev.dob, [name]: value },
        };
      });
    } else {
      setData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }

  useEffect(() => {
    const getUser = async () => {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `api/get/user/profile/${state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((res) => {
          setUserHobbies((prev) => [...prev, ...res?.data?.hobbies]);
          setUserProfession((prev) => [...prev, ...res?.data?.professions]);
          setUserQualification((prev) => [
            ...prev,
            ...res?.data?.qualifications,
          ]);
          setLoading(false);
        })
        .catch((err) => {
          if (err?.response?.data?.message == "Token has expired") {
            toast.error("Your token has been expired again sign in");
            dispatch(logOutUser());
            return;
          }
          if (state.login == true) {
            setLoading(false);
            toast.error("There is something went wrong!");
          }
        });
    };
    getUser();
  }, []);

  if (loading) {
    return (
      <div
        style={{ height: "60vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <Loading stroke={4} />
      </div>
    );
  }

  return (
    <>
      <div className="py-5 px-4 container-fluid register-form forms">
        <div className="row">
          <div className="col-xl-9 col-lg-10 mx-auto">
            <div
              className="shadow bg-white rounded-2 pb-5"
              style={{ overflow: "hidden" }}
            >
              <div className="form-header position-relative py-4 ps-3 ps-sm-4 ps-lg-5">
                <h4 className="text-white py-3 mb-0">Edit Profile</h4>
                <div className="title">
                  <div className="PolygonRuler">
                    <svg
                      x="0"
                      y="0"
                      viewBox="0 0 2560 100"
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="PolygonRuler__SVG"
                    >
                      <polygon
                        points="2560 0 2560 100 0 100"
                        className="PolygonRuler__Polygon PolygonRuler__Polygon--fill-white"
                      ></polygon>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3 mb-3 mb-md-5">
                <UserLogo
                  user={true}
                  image={data?.image}
                  name={"image"}
                  onChange={onChange}
                />
              </div>
              <div className="px-3 px-sm-4 px-lg-5 pb-5 mt-4">
                <EditUpdate
                  flag={false}
                  onChange={onChange}
                  data={data}
                  token={state.token}
                  id={user.id}
                  options={userHobbies}
                  professions={userProfessions}
                  qualification={userQualification}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
