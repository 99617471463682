import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Footer() {
  const [data, setData] = useState([]);
  const currentYear = new Date().getFullYear(); // Get the current year

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/socialLinks`)
      .then((res) => {
        setData(res?.data?.message);
      })
      .catch((err) => {
        if (err?.message === "Network Error") {
          toast.error("Check your internet Connection");
        }
      });
  }, []);

  return (
    <div style={{ zIndex: "9999" }} className="text-white py-3 px-5 footer">
      <div className="d-flex flex-column-reverse gap-2 flex-md-row justify-content-between align-items-center">
        <p style={{ fontSize: "0.8rem" }} className="p-0 m-0">
          &copy; {currentYear} Sages Wedlocks. All rights reserved.
        </p>
        <div className="d-flex justify-content-center align-items-center gap-3 order-2 order-md-0">
          {data?.map((e, index) => {
            if (e.account_name == "whatsapp" || e.account_name == "Whatsapp") {
              return (
                <a target="_blank" key={index} href={e.links}>
                  <span
                    style={{ fontSize: "1.2rem" }}
                    className="fa-brands fa-whatsapp text-white"
                  ></span>
                </a>
              );
            } else if (
              e.account_name == "Facebook" ||
              e.account_name == "facebook"
            ) {
              return (
                <a target="_blank" key={index} href={e.links}>
                  <span className="fa-brands fa-facebook text-white"></span>
                </a>
              );
            } else if (
              e.account_name == "Youtube" ||
              e.account_name == "youtube"
            ) {
              return (
                <a target="_blank" key={index} href={e.links}>
                  <span className="fa-brands fa-youtube text-white"></span>
                </a>
              );
            } else if (
              e.account_name == "instagram" ||
              e.account_name == "Instagram"
            ) {
              return (
                <a target="_blank" key={index} href={e.links}>
                  <span className="fa-brands fa-instagram text-white"></span>
                </a>
              );
            } else if (e.account_name == "x" || e.account_name == "X") {
              return (
                <a target="_blank" key={index} href={e.links}>
                  <span className="fa-brands fa-x-twitter text-white"></span>
                </a>
              );
            } else if (
              e.account_name == "tiktok" ||
              e.account_name == "Tiktok"
            ) {
              return (
                <a target="_blank" key={index} href={e.links}>
                  <span className="fa-brands fa-tiktok text-white"></span>
                </a>
              );
            } else if (
              e.account_name == "linkedin" ||
              e.account_name == "linkedIn" ||
              e.account_name == "Linkedin" ||
              e.account_name == "LinkedIn"
            ) {
              return (
                <a target="_blank" key={index} href={e.links}>
                  <span className="fa-brands fa-linkedin text-white"></span>
                </a>
              );
            }
          })}
        </div>
        <div
          style={{ fontSize: "0.8rem" }}
          className="d-flex align-items-center gap-3"
        >
          <Link to="/privacy-policy" className="text-white">
            Privacy Policy
          </Link>
          <Link to="/faq" className="text-white">
            FAQ's
          </Link>
          <Link to="/terms-&-conditions" className="text-white">
            Term & Conditions
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
