import { createSlice } from "@reduxjs/toolkit";

const authSlices = createSlice({
  name: "user",
  initialState: {
    token: null,
    user: null,
    login: false,
  },
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload.userData;
      state.token = action.payload.token;
      state.login = true;
    },
    logOutUser: (state, action) => {
      state.user = null;
      state.token = null;
      state.login = false;
    },
    updateUser: (state, action) => {
      state.user = action.payload.userData;
    },
  },
});

export const { loginUser, logOutUser, updateUser } = authSlices.actions;
export default authSlices.reducer;
