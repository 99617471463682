import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { logOutUser } from "../store/authSlices";

export const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
  const [passion, setPassion] = useState(null);
  const [featureProfile, setFeatureProfile] = useState(null);
  const [ads, setAds] = useState(null);
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState({}); // Initialize as empty object
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.user);
  const userId = state?.user?.id;
  const userToken = state?.token;
  const location = useLocation();

  useEffect(() => {
    // Clear filters when navigating to the home page or any page
    if (location.pathname == "/") {
      setFilters({});
    }
    if (location.pathname == "/featured-profiles") {
      setFilters({});
    }
    if (location.pathname == "/same-passions") {
      setFilters({});
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userId && userToken) {
      setLoader(true);
      const fetchData = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/user/get/home/data/${userId}`,
            filters,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          setFeatureProfile(response.data.featured_profiles);
          setAds(response.data.ads);
          setPassion(response.data.same_passions);
          setLoader(false);
        } catch (err) {
          if (err?.response?.data?.message == "Token has expired") {
            toast.error("Your token has been expired again sign in");
            dispatch(logOutUser());
            return;
          }
          if (err.message == "Network Error") {
            toast.error("Check you internet connection!");
          }
          toast.error("There is something went wrong!");
          setLoader(false);
        }
      };

      fetchData();
    }
  }, [userId, userToken, filters, location.pathname]); // Re-run whenever userId, userToken, or filters change

  return (
    <GlobalContext.Provider
      value={{
        passion,
        setPassion,
        featureProfile,
        setFeatureProfile,
        ads,
        setAds,
        setFilters,
        filters,
        loader,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
