import googleLogo from "../Assets/7123025_logo_google_g_icon.png";
import appleLogo from "../Assets/104490_apple_icon.png";
import { Link, useNavigate } from "react-router-dom";
import PasswordInput from "../Components/PasswordInput";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../store/authSlices";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "../Components/Loading";
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  doc,
} from "firebase/firestore";
import { firebase } from "../Components/Firebase";
import { auth, authProvider } from "../Components/Firebase";
import { signInWithPopup } from "firebase/auth";

function SignIn() {
  const db = getFirestore(firebase);

  const credentials = {
    email: "",
    name: "",
    login_type: "google",
    social_id: "",
    fcm_token: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjZjYzN",
  };

  function handleSignUpGoogle() {
    signInWithPopup(auth, authProvider)
      .then((res) => {
        localStorage.setItem("email", res?.user?.email);
        localStorage.setItem("name", res?.user?.displayName);
        localStorage.setItem(
          "social_id",
          res?.user?.reloadUserInfo?.providerUserInfo[0]?.federatedId
        );

        credentials.email = res?.user?.email;
        credentials.name = res?.user?.displayName;
        credentials.social_id =
          res?.user?.reloadUserInfo?.providerUserInfo[0]?.federatedId;

        submitCredentials();
      })
      .catch((err) => {});
  }

  async function submitCredentials() {
    setLoading(true);
    await axios
      .post(process.env.REACT_APP_API_URL + `api/socialLogin`, credentials)
      .then((res) => {
        localStorage.setItem("id", res?.data?.user?.id);
        localStorage.setItem("accessToken", res?.data?.access_token);

        if (res?.data?.type == "new") {
          navigate("/add-details");
          return;
        }

        if (res?.data?.message == "Your account is blocked by Admin!") {
          toast.error("Your account has been blocked");
          setLoading(false);
        }

        if (
          res?.data?.type !== "new" &&
          res?.data?.message == "User Login Successfully!"
        ) {
          toast.success("User Login Successfully!");
          dispatch(
            loginUser({
              userData: res?.data?.user,
              token: res?.data?.access_token,
            })
          );

          addUserToFirestore(
            res?.data?.user?.id,
            res?.data?.user?.name,
            res?.data?.user?.profession,
            res?.data?.user?.image
          );

          localStorage.clear();
          navigate("/");
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const inputRef = {
    email: useRef(),
    password: useRef(),
  };

  function onChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (value) {
      setError((prev) => {
        return {
          ...prev,
          [name]: "",
        };
      });
    } else {
      setError((prev) => {
        return {
          ...prev,
          [name]: "required",
        };
      });
    }

    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function userLogin() {
    let firstErrorKey = null;

    // Validate form data
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || !data[key]) {
        setError((prev) => ({
          ...prev,
          [key]: `${key} is required`,
        }));
        if (firstErrorKey === null) {
          firstErrorKey = key; // Capture the first error
        }
      }
    });

    // Focus on the first error input
    if (firstErrorKey) {
      inputRef[firstErrorKey]?.current?.focus();
      inputRef[firstErrorKey]?.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
      return; // Stop execution if there are validation errors
    }

    // Check if all errors are empty
    const areAllErrorsEmpty = (obj) => {
      return Object.values(obj).every((value) => value === "");
    };

    if (areAllErrorsEmpty(error)) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}api/user/login`, data)
        .then((response) => {
          if (response?.data?.message == "You are blocked by the Admin") {
            toast.error("Your account has been blocked");
            setLoading(false);
          }
          if (response.data.status === "error") {
            if (
              (response.data.message =
                "Please log in using your social account associated with this email.")
            ) {
              toast.error("Please sign in with your google account!");
              setLoading(false);
              return;
            }
            toast.error(response.data.message);
            setLoading(false);
            return; // Stop if there is an error
          }

          if (response.data.status === "success") {
            toast.success(response.data.message);
            dispatch(
              loginUser({
                userData: response.data.user,
                token: response.data.access_token,
              })
            );

            const id = response.data.user.id;
            const name = response.data.user.name;
            const image = response.data.user.image;
            const profession = response.data.user.profession;
            addUserToFirestore(id, name, profession, image);

            navigate("/");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong!");
        });
    }
  }

  const addUserToFirestore = async (userId, userName, profession, image) => {
    try {
      const userDoc = {
        id: userId,
        name: userName,
        profession: profession,
        image: image,
      };
      await setDoc(doc(db, "users", userId?.toString()), userDoc);
    } catch (error) {}
  };

  return (
    <div className="py-5 px-4 container-fluid sigin-form forms">
      <div className="row">
        <div className="col-xl-7 col-lg-8 col-md-9 mx-auto">
          <div
            className="shadow bg-white rounded-2 pb-5"
            style={{ overflow: "hidden" }}
          >
            <div className="form-header position-relative py-4 ps-3 ps-sm-4 ps-lg-5">
              <h4 className="text-white py-3 mb-0">Sign in</h4>
              <div className="title">
                <div className="PolygonRuler">
                  <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="PolygonRuler__SVG"
                  >
                    <polygon
                      points="2560 0 2560 100 0 100"
                      className="PolygonRuler__Polygon PolygonRuler__Polygon--fill-white"
                    ></polygon>
                  </svg>
                </div>
              </div>
            </div>
            <div className="px-3 px-sm-4 px-lg-5 pb-4 mt-4">
              <p className="small-heading mb-4">Sign in to your account</p>
              <div className="row">
                <div className="mb-2">
                  <div className="form-group">
                    <label className="mb-2 label">Email</label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      className="form-control"
                      value={data.email}
                      name="email"
                      ref={inputRef.email}
                      onChange={onChange}
                    />
                    {error.email && (
                      <p className="text-danger mt-2 small">
                        Email is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <PasswordInput
                      label={"Password"}
                      placeholder={"Enter your password"}
                      value={data.password}
                      name="password"
                      reference={inputRef.password}
                      onChange={onChange}
                    />
                    <div className="row w-100 p-0 align-items-end mt-2">
                      <div className="col-6">
                        {error.password && (
                          <p className="text-danger small">
                            Password is required
                          </p>
                        )}
                      </div>
                      <Link
                        to={"/forget-password"}
                        className="text-black col-6 p-0 m-0"
                      >
                        <p className="text-end p-0 m-0">Forget password?</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <button
                  disabled={loading}
                  className="btn auth-btn clr-btn-theme register-btn py-2"
                  onClick={userLogin}
                >
                  {loading ? (
                    <Loading stroke="4" width="20" height="20" />
                  ) : (
                    "Sign In"
                  )}
                </button>
                <p className="small mt-2 text-small text-center">
                  <span className="text-muted"> Don't have an account? </span>
                  <span
                    className="font-700 pointer"
                    onClick={() => navigate("/register")}
                  >
                    Sign up
                  </span>
                </p>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <span className="font-600 small">Sign In with</span>
                  <div
                    onClick={handleSignUpGoogle}
                    className="pointer google-login p-1 d-flex justify-content-center align-items-center"
                  >
                    {" "}
                    <img src={googleLogo} alt="" />
                  </div>
                  {/* <div className="pointer apple-login p-1 d-flex justify-content-center align-items-center">
                    <img src={appleLogo} alt="" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
