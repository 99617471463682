import featuredIcon from "../Assets/featured-icon.png";
import { useSelector } from "react-redux";

const MyInfo = () => {
  const { user } = useSelector((state) => {
    return state.user;
  });
  const personalInfo = {
    name: user?.name,
    profession: user?.profession,
    image: user?.image,
    profession: user?.profession,
    gender: user?.gender,
    dob: user?.dob,
    marital_status: user?.marital_status,
    featured: user?.feature_profile_status,
    highestQualification: user?.highest_qualification,
    hobbies: user?.hobbies,
  };

  const hobbiesString = personalInfo?.hobbies
    ?.replace(/[\[\]"]/g, "") // Remove brackets and quotes
    ?.split(",") // Split the string by comma
    ?.map((hobby) => hobby.trim()) // Trim any extra whitespace
    ?.join(", ");

  const newHobbiesString = hobbiesString?.replace(/\\/g, "");

  return (
    <div className="background-container my-info">
      <div className="pb-2 px-3 d-flex align-items-end justify-content-between grey-background">
        <div className="d-flex align-items-end">
          <div className="image-holder">
            {personalInfo.gender ? (
              personalInfo.gender == "Male" || personalInfo.gender == "male" ? (
                <img
                  src={
                    personalInfo.image
                      ? `${process.env.REACT_APP_API_URL + personalInfo.image}`
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                  className="user-pic"
                />
              ) : (
                <img
                  src={
                    personalInfo.image
                      ? `${process.env.REACT_APP_API_URL + personalInfo.image}`
                      : "https://cdn.vectorstock.com/i/500p/82/33/person-gray-photo-placeholder-woman-vector-24138233.jpg"
                  }
                  className="user-pic"
                />
              )
            ) : (
              <img
                src={
                  personalInfo.image
                    ? `${process.env.REACT_APP_API_URL + personalInfo.image}`
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
                className="user-pic"
              />
            )}
          </div>
          <div className="ms-2">
            <h6 className="mb-0">
              {personalInfo.name ? personalInfo.name : "N/A"}
            </h6>
            <p className="mb-0">
              {personalInfo.profession
                ? personalInfo.profession.length > 20
                  ? `${personalInfo.profession.slice(0, 20)}...`
                  : personalInfo.profession
                : "N/A"}
            </p>
          </div>
        </div>
        {personalInfo.feature_profile_status == "by admin" && (
          <img src={featuredIcon} width="30" />
        )}
      </div>
      <div className="px-sm-3 pt-sm-4 pb-sm-3 p-3">
        {personalInfo?.highestQualification && personalInfo?.profession && (
          <p>
            {personalInfo?.highestQualification} | {personalInfo?.profession} |{" "}
            {newHobbiesString}
          </p>
        )}

        <hr />
        <h6 className="mb-0">Profession</h6>
        <p>{personalInfo.profession ? personalInfo.profession : "N/A"}</p>
        <h6 className="mb-0">Gender</h6>
        <p>
          {personalInfo.gender
            ? personalInfo.gender.charAt(0).toUpperCase() +
              personalInfo.gender.slice(1)
            : "N/A"}
        </p>
        <h6 className="mb-0">Date of Birth</h6>
        <p>{personalInfo?.dob ? personalInfo.dob : "N/A"}</p>
        <h6 className="mb-0">Marital Status</h6>
        <p className="mb-0">
          {personalInfo?.marital_status
            ? personalInfo.marital_status.charAt(0).toUpperCase() +
              personalInfo.marital_status.slice(1)
            : "N/A"}
        </p>
      </div>
    </div>
  );
};

export default MyInfo;
