import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import Home from "./pages/Home";
import "./auth.css";
import "./profile.css";
import Header from "./Components/Header";
import Register from "./pages/Register";
import SignIn from "./pages/SignIn";
import Wishlist from "./pages/Wishlist";
import ForgetPassword from "./pages/ForgetPassword";
import OTP from "./pages/OTP";
import ChangePassword from "./pages/ChangePassword";
import EditProfile from "./pages/EditProfile";
import UserProfile from "./pages/UserProfile.js";
import OtherUserProfile from "./pages/OtherUserProfile.js";
import AllSamePassions from "./pages/AllSamePassions";
import AllFeaturedProfiles from "./pages/AllFeaturedProfiles.js";
import AdDetail from "./pages/AdDetail.js";
import AdsListing from "./pages/AdsListing.js";
import Feeds from "./pages/Feeds.js";
import Chat from "./pages/Chat.js";
import { ToastContainer } from "react-toastify";
import { GlobalProvider } from "./pages/GlobalProvider.js";
import MyFeeds from "./pages/MyFeeds.js";
import SignUpWithGoogle from "./pages/SignUpWithGoogle.js";
import NotFound from "./pages/NotFound.js";
import Footer from "./Components/Footer.js";
import TermsConditions from "./pages/TermsConditions.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import Faq from "./pages/Faq.js";
import FaqApp from "./pages/FaqApp.js";

const AppContent = () => {
  const location = useLocation();
  return (
    <>
      {!location.pathname.includes("faq-app-use") && <Header />}
      <GlobalProvider>
        <ToastContainer position="top-right" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/register" element={<Register />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/add-details" element={<SignUpWithGoogle />} />
          <Route
            path="/visit-user-profile/:id"
            element={<OtherUserProfile />}
          />
          <Route path="/same-passions" element={<AllSamePassions />} />
          <Route path="/featured-profiles" element={<AllFeaturedProfiles />} />
          <Route path="/ad-detail/:id" element={<AdDetail />} />
          <Route path="/ads-listing" element={<AdsListing />} />
          <Route path="/feeds" element={<Feeds />} />
          <Route path="/my-feeds" element={<MyFeeds />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat/:id" element={<Chat />} />
          <Route path="/terms-&-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/faq-app-use" element={<FaqApp />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </GlobalProvider>
      {!location.pathname.includes("faq-app-use") && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
