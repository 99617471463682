import { useEffect, useRef } from "react";

const AdComponent = () => {
  const adInitialized = useRef(false); // Ref to track if the ad has been initialized

  useEffect(() => {
    if (window.adsbygoogle && !adInitialized.current) {
      try {
        // Push ads only if not already initialized
        window.adsbygoogle.push({});
        adInitialized.current = true; // Mark as initialized
      } catch (e) {}
    }
  }, []);

  return (
    <div>
      <ins
        class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-1563886526397618"
        data-ad-slot="5140999312"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default AdComponent;
