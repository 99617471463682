import { NavLink } from "react-router-dom";
import MyInfo from "../Components/MyInfo";
import Passions from "../Components/Passions";
import FeaturedProfiles from "../Components/FeaturedProfiles";
import SamePassions from "../Components/UserProfiles.js";
import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Ads from "../Components/Ads.js";
import { toast } from "react-toastify";
import { GlobalContext } from "./GlobalProvider.js";
import Loading from "../Components/Loading.js";
import Filters from "../Components/Filters.js";
import queryString from "query-string";
import { logOutUser, updateUser } from "../store/authSlices.js";
import Download from "../Components/Download.js";
import AdComponent from "../Components/AdComponent.js";

const Home = () => {
  const state = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.login) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/get/login/user/${state?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          }
        )
        .then((res) => {
          const updatedUser = res.data.user;

          // Check if any field in updatedUser is different from state.user
          const hasUserChanged = Object.keys(updatedUser).some(
            (key) => updatedUser[key] !== state.user[key]
          );

          if (hasUserChanged) {
            // Dispatch an action to update the user data in the state
            dispatch(updateUser({ userData: updatedUser }));
          }
        })
        .catch((err) => {});
    }
  }, []);

  const [questions, setQuestions] = useState([]);
  const [answer, setAnswer] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  // Access global context values and setters
  const {
    passion,
    setPassion,
    featureProfile,
    setFeatureProfile,
    ads,
    setAds,
    loader,
    filters,
  } = useContext(GlobalContext);

  const queryStringParams = queryString.stringify(filters);

  useEffect(() => {
    let userId = state?.user?.id;
    let userToken = state?.token;

    if (state?.login == false) {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/home`)
        .then((response) => {
          setFeatureProfile(response.data.featured_profiles);
          setAds(response.data.ads);
          setPassion(response.data.same_passions);
          setLoading(false);
        })
        .catch((err) => {
          if (err?.message === "Network Error" && state?.login == true) {
            toast.error("Check your internet Connection");
          }
          setLoading(false);
        });
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/user/get/home/data/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        setFeatureProfile(response.data.featured_profiles);
        setAds(response.data.ads);
        setPassion(response.data.same_passions);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message == "Token has expired") {
          toast.error("Your token has been expired again sign in");
          dispatch(logOutUser());
        }
        if (err?.message === "Network Error" && state?.login == true) {
          toast.error("Check your internet Connection");
        }
        setLoading(false);
      });
  }, [
    state?.user?.id,
    state.token,
    state.login,
    setFeatureProfile,
    setAds,
    setPassion,
  ]);

  useEffect(() => {
    const loadQuestions = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}api/get/user/profile/${state?.user?.id}`,
          {
            headers: {
              Authorization: `Bearer ${state?.token}`,
            },
          }
        );

        const filteredQuestions = res.data.questions.filter(
          (q) => !q.answer || q.answer.length === 0
        );

        setQuestions(filteredQuestions);
      } catch (err) {
        if (err?.response?.data?.message == "Token has expired") {
          toast.error("Your token has been expired again sign in");
          dispatch(logOutUser());
        }
        if (err?.message === "Network Error" && state?.login == true) {
          toast.error("Check your internet Connection");
        }
      }
    };
    loadQuestions();
  }, [state?.user?.id, state?.token, state]);

  const currentQuestion = questions[currentIndex];

  const nextQuestionHandler = () => {
    if (questions.length == 0) {
      toast.warning("There is no more questions.");
    }
    setCurrentIndex((prev) => (prev + 1) % questions.length);
    setAnswer("");
  };

  const submitAnswer = async (questionId, answer) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/add/answer/${state?.user?.id}/${questionId}`,
        { answer },
        {
          headers: {
            Authorization: `Bearer ${state?.token}`,
          },
        }
      );
      if (res?.data?.message === "Answer Saved Successfully") {
        toast.success("Answer Saved Successfully");
      }
    } catch (err) {
      if (err?.response?.data?.message == "Token has expired") {
        toast.error("Your token has been expired again sign in");
        dispatch(logOutUser());
        return;
      }
      toast.error("There is something went wrong!");
    }
  };

  const submitHandler = () => {
    if (answer === "") {
      toast.error("Select an answer!");
      return;
    }

    const question = questions[currentIndex];
    submitAnswer(question?.question_id, answer);

    setQuestions((prevQuestions) =>
      prevQuestions?.filter((q) => q?.id !== question?.id)
    );

    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      return newIndex >= questions?.length == 0 ? 0 : newIndex;
    });

    setAnswer("");
  };

  return (
    <>
      <div className="container-fluid px-xl-5 px-sm-4">
        <div className="row mx-0">
          <div className="col-xl-3 px-xl-2 p-0">
            <MyInfo />
            <Filters />
          </div>
          <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
            <div className="px-sm-4 py-sm-3 p-2 background-container">
              <div>
                {loader == true && state?.login == true ? (
                  <div className="d-flex justify-content-center  align-items-center page">
                    <Loading stroke="4" />
                  </div>
                ) : (
                  <>
                    {questions?.length > 0 ? (
                      <>
                        <h5 className="mb-3">
                          {currentQuestion?.question?.question}
                        </h5>
                        <div className="position-relative d-flex align-items-center select-wrapper">
                          <select
                            value={answer}
                            className="question-dropdown"
                            onChange={(e) => setAnswer(e.target.value)}
                            name="answer"
                          >
                            <option value="" defaultValue disabled>
                              Choose your answer
                            </option>
                            {currentQuestion?.question?.answer?.map(
                              (e, index) => (
                                <option value={e?.answer} key={index}>
                                  {e?.answer?.charAt(0)?.toUpperCase() +
                                    e?.answer?.slice(1)}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <button
                          onClick={submitHandler}
                          className="my-sm-4 my-3 w-100 btn clr-btn-theme"
                        >
                          Submit Answer
                        </button>
                        <button
                          onClick={nextQuestionHandler}
                          className="mb-sm-4 mb-3 w-100 btn transparent-btn-theme"
                        >
                          Skip
                        </button>
                      </>
                    ) : (
                      ""
                    )}{" "}
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <h5 className="mb-0">Featured Profiles</h5>
                      <NavLink
                        to={`/featured-profiles?${queryStringParams}`}
                        className="theme-link"
                      >
                        View All
                      </NavLink>
                    </div>
                    {loading ? (
                      <Loading height="50" width="50" />
                    ) : featureProfile?.length > 0 ? (
                      <FeaturedProfiles
                        data={featureProfile}
                        setData={setFeatureProfile}
                      />
                    ) : (
                      <p className="text-center text-light p-0 m-0 border py-4 mb-4">
                        No matching profile exists
                      </p>
                    )}
                    {ads?.length != 0 && (
                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">Ads</h5>
                        <NavLink to="/ads-listing" className="theme-link">
                          View All
                        </NavLink>
                      </div>
                    )}
                    {loading ? (
                      <Loading height="50" width="50" />
                    ) : ads?.length != 0 ? (
                      <Ads data={ads} />
                    ) : (
                      <p className="text-center text-light p-0 m-0 border py-4 mb-4">
                        No ads exists
                      </p>
                    )}
                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <h5 className="mb-0">Same Passions</h5>
                      <NavLink
                        to={`/same-passions?${queryStringParams}`}
                        className="theme-link"
                      >
                        View All
                      </NavLink>
                    </div>
                    {loading ? (
                      <Loading height="50" width="50" />
                    ) : passion?.length > 0 ? (
                      <SamePassions data={passion} setData={setPassion} />
                    ) : (
                      <p className="text-center text-light p-0 m-0 border py-4 mb-4">
                        No matching profile exists
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-3 px-xl-2 p-0">
            <Passions />
            <Download />
            <AdComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
