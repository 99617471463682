import { useParams } from "react-router-dom";
import MyInfo from "../Components/MyInfo";
import Passions from "../Components/Passions";
import ad1 from "../Assets/ad-1.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logOutUser } from "../store/authSlices";
import Download from "../Components/Download";
const AdDetail = () => {
  const [data, setData] = useState();
  const param = useParams();
  const { id } = param;
  const dispatch = useDispatch();

  const state = useSelector((state) => {
    return state?.user;
  });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `api/user/get/specific/ad/${id}`, {
        headers: {
          Authorization: `Bearer ${state?.token}`,
        },
      })
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message == "Token has expired") {
          toast.error("Your token has been expired again sign in");
          dispatch(logOutUser());
          return;
        }
        if (err?.message === "Network Error" && state?.login == true) {
          toast.error("Check your internet Connection");
        }
      });
  }, []);

  const isoDateString = data?.created_at;
  const date = new Date(isoDateString);

  // Format the date to a readable format (e.g., "August 5, 2024")
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="container-fluid px-xl-5 px-sm-4">
      <div className="row mx-0">
        <div className="col-xl-3 px-xl-2 p-0">
          <MyInfo />
        </div>
        <div className="my-xl-0 my-3 col-xl-6 px-xl-2 p-0">
          <div className="p-3 background-container">
            <div className="mb-sm-4 mb-3 border-none ads">
              <img
                src={process.env.REACT_APP_API_URL + data?.image}
                className="w-100"
              />
              <div className="row my-3">
                <div className="col-sm-8">
                  <h5 className="heading">{data?.title || "N/A"}</h5>
                  <p className="mb-0 date">{formattedDate || "N/A"}</p>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.description || "N/A",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 px-xl-2 p-0">
          <Passions />
          <Download />
        </div>
      </div>
    </div>
  );
};

export default AdDetail;
